import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProductAddListValiator } from "./Validator";
import { CatAddSelector } from "./Selector";
import { API_BASE } from "../../services/config";
import {
  UpdateProductRequest,
  deleteProductRequest,
  getCatRequest,
  getProductRequest,
  postProductRequest,
} from "./Action";
import { NodeServer } from "../../services/config";
import { selectDefault } from "../../utils/Constants";

const ProductCreate = () => {
  const imageRef = useRef(null);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(ProductAddListValiator),
    defaultValues: {
      catgory_id: selectDefault,
    },
  });
  const user_id = localStorage.getItem("id");
  const {
    getCatData,
    productSendSuccess,
    getProductData,
    productUpdateSuccess,
    productDeleteSucces,
  } = useSelector(CatAddSelector);

  const [image, setImage] = useState(null);
  const [id, setId] = useState(0);
  const [imagePreview, setImagePreview] = useState(null);
console.log(imagePreview, "5yuhjk") 
  const onSubmitHandler = (formData) => {
    delete formData.content;
    formData.image = image;
    formData.user_id = user_id;
    if (id === 0) {
      dispatch(postProductRequest(formData));
      
    } else {
      formData.id = id;
      dispatch(UpdateProductRequest(formData));
     
    }
  };

  const handleEdit = (category) => {
    setId(category.id);
    setImagePreview( API_BASE + category.image );
    reset({
      name: category.name,
      description: category.description,
      featured: category.featured,
      catgory_id: category.catgory_id,
      unit_type:category?.unit_type,
      reg_price: category.reg_price,
      sale_price: category.sale_price,
    });
  };

  const handleDelete = (id) => {
    dispatch(deleteProductRequest(id));
  };

  useEffect(() => {
    dispatch(getCatRequest({}));
    dispatch(getProductRequest({}));
  }, [dispatch,]);


  useEffect(() => {
    if (getCatData.length > 0 && selectDefault) {
      setValue('catgory_id', selectDefault);
    }
  }, [getCatData, selectDefault, setValue]);
  
  useEffect(() => {
    if (productSendSuccess || productUpdateSuccess || productDeleteSucces) {
      dispatch(getProductRequest({}));
      reset({
        catgory_id:"",
        description:"",
        featured:"",
        name:"",
        reg_price:"",
        sale_price:"",
        unit_type:""
      });
      setId(0)
      setImagePreview(null); 
      setImage(null);
      if (imageRef.current) {
        imageRef.current.value = "";
      }  
    }
  }, [productSendSuccess, dispatch, productDeleteSucces, productUpdateSuccess, reset]);

  return (
    <div>
      <Row>
        <h2>{id === 0 ? "Product Create" : "Product Update"}</h2>
        <Col lg={12}>
          <Form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="p-2 bg-light border">
              <Row className="styleform">
                <Col lg={6}>
                  <Form.Group as={Col} lg={6} className="form-control">
                    <Form.Label className="label-style">Product Name</Form.Label>
                    <Form.Control type="text" {...register("name")} />
                    {errors?.name && <p className="text-danger">{errors?.name?.message}</p>}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="form-control">
                    <Form.Label>Select Category</Form.Label>
                    <Form.Control as="select" {...register("catgory_id")}>
                      <option value={selectDefault} disabled>
                        SELECT
                      </option>
                      {getCatData?.map((ele) => (
                        <option key={ele.id} value={ele.id}>{ele.name}</option>
                      ))}
                    </Form.Control>
                    {errors?.catgory_id && <p className="text-danger">{errors?.catgory_id?.message}</p>}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group as={Col} lg={6} className="form-control hide" style={{display:"none"}}>
                    <Form.Label className="label-style">Grams No</Form.Label>
                    <Form.Control type="text" {...register("unit_type")} placeholder="Like 100, 200, 500" />
                    {errors?.unit_type && <p className="text-danger">{errors?.unit_type?.message}</p>}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="styleform">
                
                <Col lg={12}>
                  <Form.Group as={Col} lg={6} className="form-control">
                    <Form.Label className="label-style">Description</Form.Label>
                    <Form.Control as="textarea" {...register("description")} />
                    {errors?.description && <p className="text-danger">{errors?.description?.message}</p>}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="styleform">
                <Col lg={6}>
                  <Form.Group as={Col} lg={6} className="form-control">
                    <Form.Label className="label-style">Featured</Form.Label>
                    <Form.Control as="textarea" {...register("featured")} />
                    {errors?.featured && <p className="text-danger">{errors?.featured?.message}</p>}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group as={Col} lg={6} className="form-control">
                    <Form.Label className="label-style">Regular Price</Form.Label>
                    <Form.Control {...register("reg_price")} type="number" />
                    {errors?.reg_price && <p className="text-danger">{errors?.reg_price?.message}</p>}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="styleform">
                <Col lg={6}>
                  <Form.Group as={Col} lg={6} className="form-control">
                    <Form.Label className="label-style">Sale Price</Form.Label>
                    <Form.Control type="number" {...register("sale_price")} />
                    {errors?.sale_price && <p className="text-danger">{errors?.sale_price?.message}</p>}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group as={Col} lg={6} className="form-control">
                    <Form.Label className="label-style">Image</Form.Label>
                    {imagePreview && (
                      <img
                        src={imagePreview}
                        alt="Product Preview"
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                      />
                    )}
                    <Form.Control
                      ref={imageRef}
                      type="file"
                      accept="image/*"
                      name="image"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          setImage(file);
                          const fileUrl = URL.createObjectURL(file);
                          setImagePreview(fileUrl);
                        }
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Form.Group className="d-flex align-items-end justify-content-end p-3 m-3">
                    <Button type="submit">
                      {id === 0 ? "Submit" : "Update"}
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Form>
        </Col>

        <Col>
          <h2>List</h2>
          <Card body className="mb-5 mt-2 d-flex">
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th>S.NO</th>
                  <th>Category Name</th>
                  <th>Product Name</th>
                  <th>Reg Price</th>
                  <th>Sale Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {getProductData &&
                  getProductData.map((ele, ind) => (
                    <tr key={ele.id}>
                      <td>{ind + 1}</td>
                      <td>{ele.category.name}</td>
                      <td>{ele.name}</td>
                      <td>{ele.reg_price}</td>
                      <td>{ele.sale_price}</td>
                      <td>
                        <Button variant="info" onClick={() => handleEdit(ele)}>Edit</Button>
                        <Button variant="danger" onClick={() => handleDelete(ele.id)}>Delete</Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ProductCreate;
