import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const signIn = (postData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/auth/signin", postData);
};

export const getusersocials = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/social/user_socials", Http.getAuthToken());
};
