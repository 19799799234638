import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const addgroup = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/user_group/create",
    payload,
    Http.getAuthToken()
  );
};
export const getProfile = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/user/getuserdetails", Http.getAuthToken());
};

export const updateProfile = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  console.log(baseUrl, "Baseurl");
  return Http.put(
    baseUrl + "/user/update_user_details",
    payload,
    Http.getAuthToken()
  );
};
export const getusergroup = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/user_group/get_usergroup", Http.getAuthToken());
};

export const getSocial = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/social/get_social", Http.getAuthToken());
};

export const updategroup = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/user_group/update",
    payload,
    Http.getAuthToken()
  );
};

export const getfbpagedetails = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/user_group/get_fb_page", Http.getAuthToken());
};

export const getSelectedGroup = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/user_group/selected_group", Http.getAuthToken());
};

export const authorizeSocial = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/user_group/authorize_social",
    payload,
    Http.getAuthToken()
  );
};

export const updateCurrent = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  console.log(payload, "kadiufhikadiufhi");
  return Http.put(
    baseUrl + "/user_group/update_current/" + payload,
    { id: payload },
    Http.getAuthToken()
  );
};

export const syncPosts = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/social/sync_facebook", Http.getAuthToken());
};
