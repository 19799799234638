export const categoryAddActionType = {
  POSTCATEGORY_REQUEST: "@@categorypage/POSTCATEGORY_REQUEST",
  POSTCATEGORY_SUCCESS: "@@categorypage/POSTCATEGORY_SUCCESS",
  POSTCATEGORY_FAILURE: "@@categorypage/POSTCATEGORY_FAILURE",

  GETCATEGORY_REQUEST: "@@categorypage/GETCATEGORY_REQUEST",
  GETCATEGORY_SUCCESS: "@@categorypage/GETCATEGORY_SUCCESS",
  GETCATEGORY_FAILURE: "@@categorypage/GETCATEGORY_FAILURE",

  UPDATECATEGORY_REQUEST: "@@categorypage/UPDATECATEGORY_REQUEST",
  UPDATECATEGORY_SUCCESS: "@@categorypage/UPDATECATEGORY_SUCCESS",
  UPDATECATEGORY_FAILURE: "@@categorypage/UPDATECATEGORY_FAILURE",


  DELETECATEGORY_REQUEST: "@@categorypage/DELETECATEGORY_REQUEST",
  DELETECATEGORY_SUCCESS: "@@categorypage/DELETECATEGORY_SUCCESS",
  DELETECATEGORY_FAILURE: "@@categorypage/DELETECATEGORY_FAILURE",


  POSTPRODUCT_REQUEST: "@@categorypage/POSTPRODUCT_REQUEST",
  POSTPRODUCT_SUCCESS: "@@categorypage/POSTPRODUCT_SUCCESS",
  POSTPRODUCT_FAILURE: "@@categorypage/POSTPRODUCT_FAILURE",

  GETPRODUCT_REQUEST: "@@categorypage/GETPRODUCT_REQUEST",
  GETPRODUCT_SUCCESS: "@@categorypage/GETPRODUCT_SUCCESS",
  GETPRODUCT_FAILURE: "@@categorypage/GETPRODUCT_FAILURE",

  UPDATEPRODUCT_REQUEST: "@@categorypage/UPDATEPRODUCT_REQUEST",
  UPDATEPRODUCT_SUCCESS: "@@categorypage/UPDATEPRODUCT_SUCCESS",
  UPDATEPRODUCT_FAILURE: "@@categorypage/UPDATEPRODUCT_FAILURE",


  DELETEPRODUCT_REQUEST: "@@categorypage/DELETEPRODUCT_REQUEST",
  DELETEPRODUCT_SUCCESS: "@@categorypage/DELETEPRODUCT_SUCCESS",
  DELETEPRODUCT_FAILURE: "@@categorypage/DELETEPRODUCT_FAILURE",

  GETUSERDATA_REQUEST: "@@categorypage/GETUSERDATA_REQUEST",
  GETUSERDATA_SUCCESS: "@@categorypage/GETUSERDATA_SUCCESS",
  GETUSERDATA_FAILURE: "@@categorypage/GETUSERDATA_FAILURE",

  GETUSERORDER_REQUEST: "@@categorypage/GETUSERORDER_REQUEST",
  GETUSERORDER_SUCCESS: "@@categorypage/GETUSERORDER_SUCCESS",
  GETUSERORDER_FAILURE: "@@categorypage/GETUSERORDER_FAILURE",

  ORDERDISPATCH_REQUEST: "@@categorypage/ORDERDISPATCH_REQUEST",
  ORDERDISPATCH_SUCCESS: "@@categorypage/ORDERDISPATCH_SUCCESS",
  ORDERDISPATCH_FAILURE: "@@categorypage/ORDERDISPATCH_FAILURE",

  GETCARDDATA_REQUEST: "@@categorypage/GETCARDDATA_REQUEST",
  GETCARDDATA_SUCCESS: "@@categorypage/GETCARDDATA_SUCCESS",
  GETCARDDATA_FAILURE: "@@categorypage/GETCARDDATA_FAILURE",

  GETDASHBOARDDATA_REQUEST: "@@categorypage/GETDASHBOARDDATA_REQUEST",
  GETDASHBOARDDATA_SUCCESS: "@@categorypage/GETDASHBOARDDATA_SUCCESS",
  GETDASHBOARDDATA_FAILURE: "@@categorypage/GETDASHBOARDDATA_FAILURE",

};

export const catAddInitialState = {
  postLoading: false,
  getLoading: false,
  sendSuccess: false,
  getCatData: [],
  updateCatLoading:false,
  catUpdatedSuccess:false,
  deleteCatLoading:false,
  catDeleteSuccess:false,
  postProLoading: false,
  productSendSuccess: false,
  getProLoading: false,
  updateProLoading: false,
  productUpdateSuccess: false,
  getProductData: [],
  deleteproductLoading:false,
  productDeleteSucces:true,
  userDataLoading:false,
  userData:[],
  userOrderLoading:false,
  userOrderData:[],
  orderDisLoad:false,
  cardLoad:false,
  cardData:[],
  dashLoad:false,
  dashData:[],
};
