import CatergroryPage from "../components/AdminPanel/CatergroryPage";
import DashBoard from "../components/AdminPanel/DashBoard";
import ProductCreate from "../components/AdminPanel/ProductCreate";
import UserDetail from "../components/AdminPanel/UserDetail";
import UserOrderDetail from "../components/AdminPanel/UserOrderDetail";
import InventryManage from "../components/InventryScreen/InventryManage";
import Login from "../components/Login";
import AllSetting from "../components/Setting/AllSetting";
import Signup from "../components/Signup";

const authProtectedRoutes = [
  // { path: "/compose-post", component: Compose },
  { path: "/catergorypage", component: CatergroryPage },
  {path:"/settingpage", component:AllSetting},
  {path:"/productcreate", component:ProductCreate},
  {path:"/userpage", component:UserDetail},
  {path:"/order/list/:id", component:UserOrderDetail},
  {path:"/inventory", component:InventryManage},
  {path:"/dashboard", component:DashBoard}
   
];

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/signup", component: Signup },
];

export { authProtectedRoutes, publicRoutes };
