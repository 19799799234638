import { call, put, takeEvery } from "redux-saga/effects";
import { deleteCatergoryApi, deleteProductApi, getcardsApi, getCatApi, getDashboardApi, getProductApi, getUserDataApi, getUserOrderDataApi, postCatApi, postOrderDispatchApi, postProductApi, updateCatergoryApi, updateProductApi } from "./Api";
import * as CategoryAddAction from "./Action";
import { categoryAddActionType } from "./Model";
import Swal from "sweetalert2";
function* handlepostData(action) {

  try {
    const response = yield call(postCatApi, action.payload);
    yield put(CategoryAddAction.postCatSuccess(response));
    console.log(response, "5trgybb");
    Swal.fire({
      title: "Success",
      text: "Catergory List Added Succesfully!",
      icon: "success",
    });
  } catch (e) {
    yield put(CategoryAddAction.postCatFailure(e));
    Swal.fire({
      title: "Error",
      text: e?.response?.data?.message,
      icon: "error",
    });
  }
}

export function* handlegetData(action) {
  try {
    const response = yield call(getCatApi, action.payload);
    yield put(CategoryAddAction.getCatSuccess(response));
    
  } catch (e) {
    yield put(CategoryAddAction.getCatFailure(e));
  }
}


function* handleUpdateCatergoryData(action) {

  try {
    const response = yield call(updateCatergoryApi, action.payload);
    yield put(CategoryAddAction.updateCatSuccess(response));
  
    Swal.fire({
      title: "Success",
      text: "Category List Updated Succesfully!",
      icon: "success",
    });
  } catch (e) {
    yield put(CategoryAddAction.updateCatFailure(e));
    Swal.fire({
      title: "Error",
      text: e?.response?.data?.message,
      icon: "error",
    });
  }
}
function* handleDeleteCatergoryData(action) {

  try {
    const response = yield call(deleteCatergoryApi, action.payload);
    yield put(CategoryAddAction.deleteCatSuccess(response));
   
    Swal.fire({
      title: "Success",
      text: "Category List Delete Succesfully!",
      icon: "success",
    });
  } catch (e) {
    yield put(CategoryAddAction.deleteCatFailure(e));
    Swal.fire({
      title: "Error",
      text: e?.response?.data?.message,
      icon: "error",
    });
  }
}

function* handlepostProductData(action) {
 
  try {
    const response = yield call(postProductApi, action.payload);
    yield put(CategoryAddAction.postProductSuccess(response));
    console.log(response, "5trgybb");
    Swal.fire({
      title: "Success",
      text: "Product List Added Succesfully!",
      icon: "success",
    });
  } catch (e) {
    yield put(CategoryAddAction.postProductFailure(e));
    Swal.fire({
      title: "Error",
      text: e?.response?.data?.message,
      icon: "error",
    });
  }
}

function* handlegetProductData(action) {
  try {
    const response = yield call(getProductApi, action.payload);
    yield put(CategoryAddAction.getProductSuccess(response));
    
  } catch (e) {
    yield put(CategoryAddAction.getProductFailure(e));
  }
}

function* handleUpdateProductData(action) {

  try {
    const response = yield call(updateProductApi, action.payload);
    yield put(CategoryAddAction.UpdateProductSuccess(response));
  
    Swal.fire({
      title: "Success",
      text: "Product List Updated Succesfully!",
      icon: "success",
    });
  } catch (e) {
    yield put(CategoryAddAction.UpdateProductFailure(e));
    Swal.fire({
      title: "Error",
      text: e?.response?.data?.message,
      icon: "error",
    });
  }
}
function* handleDeleteProductData(action) {

  try {
    const response = yield call(deleteProductApi, action.payload);
    yield put(CategoryAddAction.deleteProductSuccess(response));
   
    Swal.fire({
      title: "Success",
      text: "Product List Delete Succesfully!",
      icon: "success",
    });
  } catch (e) {
    yield put(CategoryAddAction.deleteProductFailure(e));
    Swal.fire({
      title: "Error",
      text: e?.response?.data?.message,
      icon: "error",
    });
  }
}

function* handlegetUserData(action) {
  try {
    const response = yield call(getUserDataApi, action.payload);
    yield put(CategoryAddAction.getUserDataSuccess(response));
    
  } catch (e) {
    yield put(CategoryAddAction.getUserDataFailure(e));
  }
}

function* handlegetUserOrderData(action) {
  try {
    const response = yield call(getUserOrderDataApi, action.payload);
    yield put(CategoryAddAction.getUserOrderDataSuccess(response));
    
  } catch (e) {
    yield put(CategoryAddAction.getUserOrderDataFailure(e));
  }
}
function* handleOrderDispatch(action) {
  try {
    const response = yield call(postOrderDispatchApi, action.payload);
    yield put(CategoryAddAction.orderDispatchSuccess(response));
    
  } catch (e) {
    yield put(CategoryAddAction.orderDispatchFailure(e));
  }
}
//
function* handleGetCardData(action) {
  try {
    const response = yield call(getcardsApi, action.payload);
    yield put(CategoryAddAction.getCardDataSuccess(response));
    
  } catch (e) {
    yield put(CategoryAddAction.getCardDataFailure(e));
  }
}

function* handleGetDashBoardData(action) {
  try {
    const response = yield call(getDashboardApi, action.payload);
    yield put(CategoryAddAction.getDashBoardDataSuccess(response));
    
  } catch (e) {
    yield put(CategoryAddAction.getDashBoardDataFailure(e));
  }
}
export function* CategoryAddSaga() {
  yield takeEvery(categoryAddActionType.POSTCATEGORY_REQUEST, handlepostData);
  yield takeEvery(categoryAddActionType.GETCATEGORY_REQUEST, handlegetData);
  yield takeEvery(
    categoryAddActionType.UPDATECATEGORY_REQUEST,
    handleUpdateCatergoryData
  );

  yield takeEvery(
    categoryAddActionType.DELETECATEGORY_REQUEST,
    handleDeleteCatergoryData
  );

  yield takeEvery(
    categoryAddActionType.POSTPRODUCT_REQUEST,
    handlepostProductData
  );
  yield takeEvery(
    categoryAddActionType.GETPRODUCT_REQUEST,
    handlegetProductData
  );
  yield takeEvery(
    categoryAddActionType.UPDATEPRODUCT_REQUEST,
    handleUpdateProductData
  );

  yield takeEvery(
    categoryAddActionType.DELETEPRODUCT_REQUEST,
    handleDeleteProductData
  );

  yield takeEvery(categoryAddActionType.GETUSERDATA_REQUEST, handlegetUserData);
  yield takeEvery(categoryAddActionType.GETUSERORDER_REQUEST, handlegetUserOrderData);
  yield takeEvery(categoryAddActionType.ORDERDISPATCH_REQUEST, handleOrderDispatch);
  yield takeEvery(categoryAddActionType.GETCARDDATA_REQUEST, handleGetCardData);
  yield takeEvery(categoryAddActionType.GETDASHBOARDDATA_REQUEST, handleGetDashBoardData);

}
