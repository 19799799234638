import { action } from "typesafe-actions";
import { InventeryManageActionType } from "./Model";

export const postInventryDataRequest=(req)=>
    action(InventeryManageActionType.POSTMANAGEINVENTRY_REQUEST, req)
export const postInventryDataSuccess=(res)=>
    action(InventeryManageActionType.POSTMANAGEINVENTRY_SUCCESS, res)
export const postInventryDataFailure=(err)=>
    action(InventeryManageActionType.POSTMANAGEINVENTRY_FAILURE, err)

export const getInventryDataRequest=(req)=>
    action(InventeryManageActionType.GETNVENTRYDATA_REQUEST, req)
export const getInventryDataSuccess=(res)=>
    action(InventeryManageActionType.GETNVENTRYDATA_SUCCESS, res)
export const getInventryDataFailure=(err)=>
    action(InventeryManageActionType.GETNVENTRYDATA_FAILURE, err)

export const updateInventryDataRequest=(req)=>
    action(InventeryManageActionType.UPDATEINVENTRYDATA_REQUEST, req)
export const updateInventryDataSuccess=(res)=>
    action(InventeryManageActionType.UPDATEINVENTRYDATA_SUCCESS, res)
export const updateInventryDataFailure=(err)=>
    action(InventeryManageActionType.UPDATEINVENTRYDATA_FAILURE, err)