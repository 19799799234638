import React, { useEffect } from "react";
import { CatAddSelector } from "./Selector";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { NodeServer } from "../../services/config";
import { useParams } from "react-router-dom";
import { getUserOrderDataRequest, orderDispatchRequest } from "./Action";
import { Button, Table } from "react-bootstrap";
import { orderStatusData, statusData } from "../../utils/Constants";

const UserOrderDetail = () => {
  const dispatch = useDispatch();

  const id = useParams();
  console.log(typeof id, "fkjsd");
  const { userData, userOrderData } = useSelector(CatAddSelector);

  console.log(userOrderData.data, "fsfjskd");
/* States */

  /* Functions */

  const handleSuccessClick = (id, ind) => {
    const payload={
      id,
      status:2,
    }
    dispatch(orderDispatchRequest(payload))
  }
  console.log()
  const handleRejectClick = (id, ind) => {
    const payload={
      id,
      status:"",
      order_status:4
    }
    dispatch(orderDispatchRequest(payload))
  }
  const renderButtons = (status, id, index) => {
    console.log(status, id, index, "45tygvhbn");
    switch (status) {
      case 0:
        return (
          <>
           
            <Button
              variant="danger"
              onClick={() => handleRejectClick(id, index)}
            >Reject
            </Button>
          </>
        );
        case 1:
          return (
            <>
             
              <Button
                variant="danger"
                onClick={() => handleRejectClick(id, index)}
              >Rejected
              </Button>
            </>
          );
      case 2:
        return (
          <>
             <Button onClick={() => handleSuccessClick(id, index)}>
            Dispatch
            </Button>
            <Button
              variant="danger"
              onClick={() => handleRejectClick(id, index)}
            >Rejected
            </Button>
          </>
        );
      default:
        return null;
    }
  };
  /* useEffect */

  useEffect(() => {
    console.log(id, "fsdjkf");
    let val = id.id;
    dispatch(getUserOrderDataRequest(val));
  }, []);
  return (
    <div>
      <div class="">
        <Table bordered size="sm">
          <thead>
            <tr>
              <th>Product Image</th>
              <th>Order Detail</th>
              <th>Status</th>
              <th>Order Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {userOrderData?.data &&
              userOrderData?.data?.map((orders) => {
                console.log(orders, "ORDERSSSS");
                return (
                  <tr>
                    <td className="left">
                        <img className="leftimg"
                          src={NodeServer.baseURL + orders?.products?.image}
                          alt=""
                        />
                    </td>
                    <td className="re-order"> 
                      <div className="right">
                        <h4>{orders?.products?.name}</h4>
                        <span class="qty">
                          <b>Order Quantity :</b> {orders?.quantity}
                        </span>
                        <span class="date">
                          <b>Order Date :</b>
                          {dayjs(orders.updated_at).format("YYYY/MM/DD")}
                        </span>
                        </div>
                    </td>
                    <td className="re-order">
                      <div>
                      <p variant="success"  disabled>
                        {statusData[orders.orders.status] }
                      </p>
                      </div>
                    </td>
                    <td className="re-order">
                      <p variant="success"  disabled>
                        {orderStatusData[orders.orders.order_status]}
                      </p>
                    </td>
                    <td className="re-order">  {renderButtons(orders.orders.order_status,orders?.orders?.id)}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default UserOrderDetail;
