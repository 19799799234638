/* External Imports */
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
/* Internal Imports */
import { CategoryAddListValiator } from "./Validator";
import { deleteCatRequest, getCatRequest, postCatRequest, updateCatRequest } from "./Action";
import { useForm } from "react-hook-form";
import { CatAddSelector } from "./Selector";

const CatergroryPage = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(CategoryAddListValiator),
  });

  /* Selectors */
  const { getCatData, sendSuccess, catUpdatedSuccess,catDeleteSuccess } = useSelector(CatAddSelector);
  console.log(getCatData, "65dcsf")

  /* States */
  const [id, setId] = useState(0);

  /* Function */
  const onSubmitHandler = (formData) => {
    console.log(formData, "23dertcfv");
    if(id==0){
    dispatch(postCatRequest(formData));
  }else{
    dispatch(updateCatRequest({formData, id}))
    setId(0)
  }
  // if (sendSuccess == true || catUpdatedSuccess==true || catDeleteSuccess==true ) {
    
  // setId(0)}
  };
  const handleEdit = (cat) => {
setId(cat.id)
reset({
  name:cat.name,
  description:cat.description
})
  }
  const handleDelete=(id)=>{
    dispatch(deleteCatRequest(id))

  }


  /* UseEffect */
  useEffect(() => {
    dispatch(getCatRequest({}));
  }, []);

  useEffect(() => {
    if (sendSuccess == true || catUpdatedSuccess==true || catDeleteSuccess==true ) {
      dispatch(getCatRequest({}));
      reset({
        description:"",
        name:""
      })
    }
  }, [sendSuccess, dispatch, catUpdatedSuccess,catDeleteSuccess]);

  return (
    <div>
      <Row className="h-100">
        <Col>
          <Col>
          <h2 className="text-muted">Category {id==0? "Add":"Update" } </h2>
            <Card body className="mb-5 mt-2">
     
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Row className="mb-3">
                  <Form.Group as={Col} lg={4} className="food-menu">
                    <Form.Label className="label-style">
                      Category Name
                    </Form.Label>
                    <Form.Control type="text" {...register("name")} />
                    {errors?.name && (
                      <p className="text-danger">{errors?.name?.message}</p>
                    )}
                  </Form.Group>

                  <Form.Group as={Col} lg={4}>
                    <Form.Label className="label-style">Description</Form.Label>
                    <Form.Control as="textarea"{...register("description")} />
                    {errors?.description && (
                      <p className="text-danger">
                        {errors?.description?.message}
                      </p>
                    )}
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    lg={2}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Button type="submit">{id==0? "Submit":"Update"}</Button>
                  </Form.Group>
                </Row>
              </form>
            </Card>
          </Col>
          <Col>
          <h2 >List</h2>

            <Card body className="mb-5 mt-2 d-flex">

              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Catergory Name </th>
                    <th>Description</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {getCatData &&
                    getCatData?.map((ele, ind) => {
                      return (
                        <tr key={ele.id}>
                          <td>{ind+1}</td>
                          <td>{ele.name}</td>
                          <td>{ele.description}</td>
                          <td>{dayjs(ele.created_at).format("DD-MM-YY")}</td>
                          <td>
                            {" "}
                            <td>
                              <Button
                                variant="info"
                                onClick={() => handleEdit(ele)}
                              >
                                Edit
                              </Button>{" "}
                              <Button
                                variant="danger"
                                onClick={() => handleDelete(ele.id)}
                              >
                                Delete
                              </Button>
                            </td>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default CatergroryPage;
