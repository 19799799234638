import * as Yup from "yup";

export const CategoryAddListValiator = Yup.object().shape({
  name: Yup.string().required("Category Name  Required"),
  description: Yup.string().required("Category Desctiption Required"),
});

export const ProductAddListValiator = Yup.object().shape({
  name: Yup.string().required("Product Name  Required"),
  unit_type:Yup.number().required("Grams Required"),
  description: Yup.string().required("Prodcut Desctiption Required"),
  reg_price: Yup.string().required("Regular Price Required"),
  sale_price: Yup.string().required("Sale Price Required"),
  featured: Yup.string().required("Featured Required"),
  catgory_id:Yup.number().required("CategoryID Required"),
});
export const InventryCreateValidator=Yup.object().shape({
  product_id:Yup.number().required("Select Product"),
  opening_qty: Yup.number().required("Opening Quantity Required"),
  current_qty: Yup.number().required("Current Quantity Required"),
  inward_qty: Yup.number().nullable(),
  unit_type:Yup.number().required("Grams Required"),
  outward_qty: Yup.number().notRequired()
})