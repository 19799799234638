import { decryptdata } from "../../utils/encrypt&decrypt";
import { catAddInitialState, categoryAddActionType } from "./Model";

const reducer = (state = catAddInitialState, action) => {
  switch (action.type) {
    case categoryAddActionType.POSTCATEGORY_REQUEST:
      return {
        ...state,
        postLoading: true,
        sendSuccess: false,
      };
    case categoryAddActionType.POSTCATEGORY_SUCCESS:
      return {
        ...state,
        postLoading: false,
        sendSuccess: true,
      };
    case categoryAddActionType.POSTCATEGORY_FAILURE:
      return {
        ...state,
        postLoading: false,
        sendSuccess: false,
      };
    case categoryAddActionType.GETCATEGORY_REQUEST:
      return {
        ...state,
        getLoading: true,
      };
    case categoryAddActionType.GETCATEGORY_SUCCESS:
      const { payload } = action;
      console.log(payload, process.env.SECRET_KEY, "jkmls");
      let payloaddatas = JSON.parse(
        decryptdata(
          payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(payloaddatas.data, "yhoio");
      return {
        ...state,
        getLoading: false,
        getCatData: payloaddatas?.data,
      };
    case categoryAddActionType.GETCATEGORY_FAILURE:
      return {
        ...state,
        getLoading: false,
      };

    case categoryAddActionType.UPDATECATEGORY_REQUEST:
      return {
        ...state,
        updateCatLoading: true,
        catUpdatedSuccess: false,
      };
    case categoryAddActionType.UPDATECATEGORY_SUCCESS:
      return {
        ...state,
        updateCatLoading: false,
        catUpdatedSuccess: true,
      };
    case categoryAddActionType.UPDATECATEGORY_FAILURE:
      return {
        ...state,
        updateCatLoading: false,
        catUpdatedSuccess: false,
      };

    case categoryAddActionType.DELETECATEGORY_REQUEST:
      return {
        ...state,
        deleteCatLoading: true,
        catDeleteSuccess: false,
      };
    case categoryAddActionType.DELETECATEGORY_SUCCESS:
      return {
        ...state,
        deleteCatLoading: false,
        catDeleteSuccess: true,
      };
    case categoryAddActionType.DELETECATEGORY_FAILURE:
      return {
        ...state,
        deleteCatLoading: false,
        catDeleteSuccess: false,
      };

    case categoryAddActionType.POSTPRODUCT_REQUEST:
      return {
        ...state,
        postProLoading: true,
        productSendSuccess: false,
      };
    case categoryAddActionType.POSTPRODUCT_SUCCESS:
      return {
        ...state,
        postProLoading: false,
        productSendSuccess: true,
      };
    case categoryAddActionType.POSTPRODUCT_FAILURE:
      return {
        ...state,
        postProLoading: false,
        productSendSuccess: false,
      };
    case categoryAddActionType.GETPRODUCT_REQUEST:
      return {
        ...state,
        getProLoading: true,
      };
    case categoryAddActionType.GETPRODUCT_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "jkmls");
      let payloaddatas1 = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(payloaddatas1.data, "yhoio");
      return {
        ...state,
        getProLoading: false,
        getProductData: payloaddatas1?.data,
      };
    case categoryAddActionType.GETPRODUCT_FAILURE:
      return {
        ...state,
        getProLoading: false,
      };
    case categoryAddActionType.UPDATEPRODUCT_REQUEST:
      return {
        ...state,
        updateProLoading: true,
        productUpdateSuccess: false,
      };
    case categoryAddActionType.UPDATEPRODUCT_SUCCESS:
      return {
        ...state,
        updateProLoading: false,
        productUpdateSuccess: true,
      };
    case categoryAddActionType.UPDATEPRODUCT_FAILURE:
      return {
        ...state,
        updateProLoading: false,
        productUpdateSuccess: false,
      };

    case categoryAddActionType.DELETEPRODUCT_REQUEST:
      return {
        ...state,
        deleteproductLoading: true,
        productDeleteSucces: false,
      };
    case categoryAddActionType.DELETEPRODUCT_SUCCESS:
      return {
        ...state,
        deleteproductLoading: false,
        productDeleteSucces: true,
      };
    case categoryAddActionType.DELETEPRODUCT_FAILURE:
      return {
        ...state,
        deleteproductLoading: false,
        productDeleteSucces: false,
      };
    /*  Reducer for  Get UserData */

    case categoryAddActionType.GETUSERDATA_REQUEST:
      return {
        ...state,
        userDataLoading: true,
      };
    case categoryAddActionType.GETUSERDATA_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "jkmls");
      let payloaddatas2 = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(payloaddatas2.data, "yhoio");
      return {
        ...state,
        userDataLoading: false,
        userData: payloaddatas2?.data,
      };
    case categoryAddActionType.GETUSERDATA_FAILURE:
      return {
        ...state,
        userDataLoading: false,
      };

    /* Reducer for Get User Order Data  */
    case categoryAddActionType.GETUSERORDER_REQUEST:
      return {
        ...state,
        userOrderLoading: true,
      };
    case categoryAddActionType.GETUSERORDER_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "jkmls");
      let payloaddatas3 = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(payloaddatas3?.data, "yhoioasd");
      return {
        ...state,
        userOrderLoading: false,
        userOrderData: payloaddatas3,
      };
    case categoryAddActionType.GETUSERORDER_FAILURE:
      return {
        ...state,
        userOrderLoading: false,
      };

    case categoryAddActionType.ORDERDISPATCH_REQUEST:
      return {
        ...state,
        postProLoading: true,
        productSendSuccess: false,
      };
    case categoryAddActionType.ORDERDISPATCH_SUCCESS:
      return {
        ...state,
        postProLoading: false,
        productSendSuccess: true,
      };
    case categoryAddActionType.ORDERDISPATCH_FAILURE:
      return {
        ...state,
        postProLoading: false,
        productSendSuccess: false,
      };
    case categoryAddActionType.GETCARDDATA_REQUEST:
      return {
        ...state,
        cardLoad: true,
      };
    case categoryAddActionType.GETCARDDATA_SUCCESS:
      return {
        ...state,
        cardLoad: false,
        cardData:action.payload?.data?.data
      };
    case categoryAddActionType.GETCARDDATA_FAILURE:
      return {
        ...state,
        cardLoad: false,
      };
    case categoryAddActionType.GETDASHBOARDDATA_REQUEST:
      return {
        ...state,
        dashLoad: true,
      };
    case categoryAddActionType.GETDASHBOARDDATA_SUCCESS:
      return {
        ...state,
        dashLoad: false,
        dashData:action.payload?.data?.data
      };
    case categoryAddActionType.GETDASHBOARDDATA_FAILURE:
      return {
        ...state,
        dashLoad: false,
      };
    default:
      return state;
  }
};
export { reducer as CategoryAddReducer };
