import { DEFAULT_SETTINGS } from "../../utils/config";
import {
  GroupActionTypes,
  getComputedValues,
  groupInitialState,
} from "./settings.model";

const reducer = (state = groupInitialState, action) => {
  switch (action.type) {
    case GroupActionTypes.ADD_GROUP_SUCCESS:
      var { payload } = action;
      console.log(payload, "add success payload");
      return {
        ...state,
        groupLoading: false,
        groupMessage: payload?.data?.message,
      };
    case GroupActionTypes.ADD_GROUP_REQUEST:
      return {
        ...state,
        groupLoading: true,
      };
    case GroupActionTypes.ADD_GROUP_FAILURE:
      var { payload } = action;
      return {
        ...state,
        groupLoading: false,
        groupError: payload.response.data.message,
      };
    case GroupActionTypes.CLEAR_MESSAGES:
      return {
        ...state,
        groupError: "",
        groupMessage: "",
      };
    case GroupActionTypes.GET_USERGROUP_SUCESS:
      var { payload } = action;
      return {
        ...state,
        group: payload?.data?.data,
      };
    case GroupActionTypes.GET_USERGROUP_REQUEST:
      return {
        ...state,
        // groupLoading: true,
      };
    case GroupActionTypes.GET_USERGROUP_FAILURE:
      return {
        ...state,
        // groupLoading: true,
      };
    case GroupActionTypes.GET_SOCIAL_SUCESS:
      var { payload } = action;
      console.log(payload, "payload GTE");
      return {
        ...state,
        socialList: payload?.data,
      };
    case GroupActionTypes.UPDATE_USERGROUP_REQUEST:
      var { payload } = action;
      return {
        ...state,
        accountSubmit: true,
      };
    case GroupActionTypes.UPDATE_USERGROUP_FAILURE:
      var { payload } = action;
      return {
        ...state,
        accountSubmit: false,
      };
    case GroupActionTypes.UPDATE_USERGROUP_SUCESS:
      var { payload } = action;
      return {
        ...state,
        accountSubmit: false,
        groupMessage: "Socials Updated Successfully",
      };
    case GroupActionTypes.GET_CURRENT_GRP_SUCESS:
      var { payload } = action;
      return {
        ...state,
        current_group: payload?.data?.data,
      };
    case GroupActionTypes.SET_CRNT_GRP_SUCESS:
      var { payload } = action;
      return {
        ...state,
        groupMessage: "Current Group Updated",
      };
    case GroupActionTypes.AUTH_SOCIALS_SUCESS:
      var { payload } = action;
      console.log(payload);
      return {
        ...state,
        groupMessage: payload?.data?.message,
      };
    case GroupActionTypes.GET_FB_PAGE_SUCESS:
      var { payload } = action;
      return {
        ...state,
        groupMessage: payload?.data?.message,
      };
    case GroupActionTypes.GET_PROFILE_SETTING_REQUEST:
      return {
        ...state,
        profileLoading: true,
      };
    case GroupActionTypes.GET_PROFILE_SETTING_SUCCESS:
      var { payload } = action;
      console.log(payload, "fromGetProfileconsole");
      return {
        ...state,
        profileLoading: false,
        profileDetails: payload,
      };
    case GroupActionTypes.GET_PROFILE_SETTING_FAILURE:
      var { payload } = action;
      return {
        ...state,
        profileLoading: false,
        groupMessage: payload?.data.message,
      };
    case GroupActionTypes.POST_PROFILE_SETTING_REQUEST:
      return {
        ...state,
        profileLoading: true,
      };
    case GroupActionTypes.POST_PROFILE_SETTING_SUCCESS:
      var { payload } = action;
      console.log(payload, "fjgdkddfjd");
      return {
        ...state,
        profileLoading: false,
        groupMessage: payload?.data?.message,
        profileDetail: payload,
      };
    case GroupActionTypes.POST_PROFILE_SETTING_FAILURE:
      var { payload } = action;
      return {
        ...state,
        profileLoading: false,
        groupMessage: payload?.data.message,
      };
    case GroupActionTypes.SET_COLOR:
      return {
        ...state,
        color: action.payload,
      };
    case GroupActionTypes.SET_THEME_VALUES:
      return {
        ...state,
        themeValues: getComputedValues(),
      };
    case GroupActionTypes.SETTINGS_CHANGE_RADIUS:
      return {
        ...state,
        radius: action.payload,
      };
    case GroupActionTypes.SETTINGS_CHANGE_NAVCOLOR:
      return {
        ...state,
        navColor: action.payload,
      };
    case GroupActionTypes.SETTINGS_CHANGE_LAYOUT:
      return {
        ...state,
        layout: action.payload,
      };
    case GroupActionTypes.SETTINGS_RESET_LAYOUT:
      return {
        ...state,
        layout: DEFAULT_SETTINGS.LAYOUT,
      };
    case GroupActionTypes.POST_SYNC_REQUEST:
      var { payload } = action;
      return {
        ...state,
        syncLoad: true,
      };
    case GroupActionTypes.POST_SYNC_SUCCESS:
      var { payload } = action;
      return {
        ...state,
        syncedData: payload?.data?.data,
        syncLoad: false,
      };
    case GroupActionTypes.POST_SYNC_FAILURE:
      var { payload } = action;
      return {
        ...state,
        syncLoad: false,
      };
    case GroupActionTypes.CLEAR_FB_POSTS:
      return {
        ...state,
        syncedData: [],
      };
    default:
      return state;
  }
};

export { reducer as SettingReducer };
