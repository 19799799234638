import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const postCatApi = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/category/create", formData, Http.getAuthToken());
};
export const getCatApi = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/category/", Http.getAuthToken());
};

export const updateCatergoryApi = ({ formData, id }) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/category/update/" + id,
    formData,
    Http.getAuthToken()
  );
};

export const deleteCatergoryApi = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/category/delete/" + id, Http.getAuthToken());
};

export const postProductApi = (formData) => {
  const abortController = new AbortController();
  const prop = { signal: abortController.signal };
  const baseUrl = getBaseEndpointUrl();
  console.log(formData, "rtghbjkm");
  return Http.post(
    baseUrl + "/product/create",
    formData,
    Http.getFileAuthToken(prop)
  );
};
export const getProductApi = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/product/", {});
};

export const updateProductApi = (formData) => {
  console.log(formData, "56tybhj")
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/product/update/" + formData?.id,
    formData,
    Http.getFileAuthToken()
  );
};

export const deleteProductApi = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/product/delete/" + id, Http.getAuthToken());
};

export const getUserDataApi = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/user/", Http.getAuthToken());
};
export const getUserOrderDataApi = (query) => {
  console.log(query, "3erftyh");
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/orders/list?query=" + query, Http.getAuthToken());
};

export const postOrderDispatchApi = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/orders/dispatch-status",
    formData,
    Http.getAuthToken()
  );
};

export const getcardsApi = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + `/dashboard/carddata`, Http.getAuthToken());
};
export const getDashboardApi = (query) => {
  console.log(query, 77834567)
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl + "/dashboard/chartdata/?period=" + query.period,
    Http.getAuthToken()
  );
};
