import React from "react";
import { Card } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import BannerManage from "./BannerManage";
import Setting from "./Setting";
const AllSetting = () => {
  return (
    <div>
      <Card>
        <Tabs>
          <TabList>
            <Tab>Setting</Tab>
            <Tab>Banner Manage</Tab>
          </TabList>
          <TabPanel>
           <Setting/>
          </TabPanel>
          <TabPanel>
            <BannerManage />
          </TabPanel>
        </Tabs>
      </Card>
    </div>
  );
};

export default AllSetting;
