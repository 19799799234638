import { all } from "redux-saga/effects";
import { AuthSaga } from "./auth/auth.saga";
import { SettingSaga } from "./settings/settings.saga";
import { UserSaga } from "./user/user.saga";
import { CategoryAddSaga } from "../components/AdminPanel/Saga";
import { AdminsettingSaga } from "../components/Setting/Saga";
import { InventrySaga } from "../components/InventryScreen/Saga";

export default function* rootSaga() {
  yield all([
    AuthSaga(),
    SettingSaga(),
    UserSaga(),
    CategoryAddSaga(),
    AdminsettingSaga(),
    InventrySaga(),
  ]);
}
