import { DEFAULT_SETTINGS } from "../../utils/config";
export const BREAKPOINTS = { sm: 576, md: 768, lg: 992, xl: 1200, xxl: 1400 };

export const MenuActionTypes = {
  MENUCHANGEPLACEMENT: "@@menu/MENUCHANGEPLACEMENT",
  MENUCHANGEPLACEMENTSTATUS: "@@menu/MENUCHANGEPLACEMENTSTATUS",
  MENUCHANGBEHAVIOUR: "@@menu/MENUCHANGBEHAVIOUR",
  MENUCHANGBEHAVIOURSTATUS: "@@menu/MENUCHANGBEHAVIOURSTATUS",
  MENUCHANGEPINBUTTONENABLE: "@@menu/MENUCHANGEPINBUTTONENABLE",
  MENUCHANGEATTRMENUANIMATE: "@@menu/MENUCHANGEATTRMENUANIMATE",
  MENUCHANGEATTRMOBILE: "@@menu/MENUCHANGEATTRMOBILE",
  MENUCHANGECOLLAPSEALL: "@@menu/MENUCHANGECOLLAPSEALL",
  MENUCHANGENAVCLASSES: "@@menu/MENUCHANGENAVCLASSES",
  MENUCHANGEBREAKPOINTS: "@@menu/MENUCHANGEBREAKPOINTS",
  MENURESETBREAKPOINTS: "@@menu/MENURESETBREAKPOINTS",
  MENUCHANGEUSERSIDEBAR: "@@menu/MENUCHANGEUSERSIDEBAR",
  MENURESETUSERSIDEBAR: "@@menu/MENURESETUSERSIDEBAR",
  MENUCHANGEMENUPADDING: "@@menu/MENUCHANGEMENUPADDING",
};

export const menuInitialState = {
  behaviour: DEFAULT_SETTINGS.MENU_BEHAVIOUR,
  placement: DEFAULT_SETTINGS.MENU_PLACEMENT,
  useSidebar: DEFAULT_SETTINGS.USE_SIDEBAR,
  pinButtonEnable: true,
  placementStatus: {},
  behaviourStatus: {},
  navClasses: {},
  attrMobile: false,
  attrMenuAnimate: "",
  collapseAll: false,
  breakpoints: {
    verticalUnpinned: BREAKPOINTS.xxl,
    verticalMobile: BREAKPOINTS.lg,
    horizontalMobile: BREAKPOINTS.lg,
  },
  menuPadding: 0,
};
