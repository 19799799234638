import * as Yup from "yup";

export const ManageBannerValidator = Yup.object().shape({
  name: Yup.string().required(" Name  Required"),
  content: Yup.string().required("Content Required"),
  description: Yup.string().required("Description Required"),
  quote: Yup.string().required("Quote Required"),
});

 export const SettingValidation = Yup.object().shape({
  tax: Yup.string().required('Tax is required'),
  delivery_text: Yup.string().required('Delivery Text is required'),
  address: Yup.string().notRequired(),
  contact: Yup.string().notRequired(),
  contact_gmail: Yup.string().notRequired(),
  gmail: Yup.string().notRequired(),
  map_url: Yup.string().notRequired(),
  delivery_percent: Yup.string().notRequired(),
  max_range: Yup.string().notRequired(),
  min_range: Yup.string().notRequired(),
});