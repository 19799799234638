import { MenuActionTypes, menuInitialState, BREAKPOINTS } from "./menu.model";

const reducer = (state = menuInitialState, action) => {
  switch (action.type) {
    case MenuActionTypes.MENUCHANGEPLACEMENT:
      return {
        ...state,
        placement: action.payload,
      };
    case MenuActionTypes.MENUCHANGEPLACEMENTSTATUS:
      return {
        ...state,
        placementStatus: action.payload,
      };
    case MenuActionTypes.MENUCHANGBEHAVIOUR:
      return {
        ...state,
        behaviour: action.payload,
      };
    case MenuActionTypes.MENUCHANGBEHAVIOURSTATUS:
      return {
        ...state,
        behaviourStatus: action.payload,
      };
    case MenuActionTypes.MENUCHANGEPINBUTTONENABLE:
      return {
        ...state,
        pinButtonEnable: action.payload,
      };
    case MenuActionTypes.MENUCHANGEATTRMENUANIMATE:
      return {
        ...state,
        attrMenuAnimate: action.payload,
      };
    case MenuActionTypes.MENUCHANGEATTRMOBILE:
      return {
        ...state,
        attrMobile: action.payload,
      };
    case MenuActionTypes.MENUCHANGECOLLAPSEALL:
      return {
        ...state,
        collapseAll: action.payload,
      };
    case MenuActionTypes.MENUCHANGENAVCLASSES:
      return {
        ...state,
        navClasses: action.payload,
      };
    case MenuActionTypes.MENUCHANGEBREAKPOINTS:
      return {
        ...state,
        breakpoints: {
          ...state.breakpoints,
          ...action.payload,
        },
      };
    case MenuActionTypes.MENURESETBREAKPOINTS:
      return {
        ...state,
        breakpoints: {
          verticalUnpinned: BREAKPOINTS.xxl,
          verticalMobile: BREAKPOINTS.lg,
          horizontalMobile: BREAKPOINTS.lg,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export { reducer as menu };
