export const UserActionTypes = {
  UPDATE_USER_REQUEST: "@@user/UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "@@user/UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "@@user/UPDATE_USER_FAILURE",

  UPDATE_TOKEN_REQUEST: "@@user/UPDATE_TOKEN_REQUEST",
  UPDATE_TOKEN_SUCCESS: "@@user/UPDATE_TOKEN_SUCCESS",
  UPDATE_TOKEN_FAILURE: "@@user/UPDATE_TOKEN_FAILURE",

  VERIFY_PIN_REQUEST: "@@user/VERIFY_PIN_REQUEST",
  VERIFY_PIN_SUCCESS: "@@user/VERIFY_PIN_SUCCESS",
  VERIFY_PIN_FAILURE: "@@user/VERIFY_PIN_FAILURE",

  GET_STATUS_REQUEST: "@@user/GET_STATUS_REQUEST",
  GET_STATUS_SUCCESS: "@@user/GET_STATUS_SUCCESS",
  GET_STATUS_FAILURE: "@@user/GET_STATUS_FAILURE",

  FETCH_BALANCE_REQUEST: "@@user/FETCH_BALANCE_REQUEST",
  FETCH_BALANCE_SUCCESS: "@@user/FETCH_BALANCE_SUCCESS",
  FETCH_BALANCE_FAILURE: "@@user/FETCH_BALANCE_FAILURE",

  UPDATE_SETTINGS_REQUEST: "@@user/UPDATE_SETTINGS_REQUEST",
  UPDATE_SETTINGS_SUCCESS: "@@user/UPDATE_SETTINGS_SUCCESS",
  UPDATE_SETTINGS_FAILURE: "@@user/UPDATE_SETTINGS_FAILURE",

  GET_USERSOCIAL_REQUEST: "@@user/GET_USERSOCIAL_REQUEST",
  GET_USERSOCIAL_SUCCESS: "@@user/GET_USERSOCIAL_SUCCESS",
  GET_USERSOCIAL_FAILURE: "@@user/GET_USERSOCIAL_FAILURE",

  USER_LOGOUT: "@@user/USER_LOGOUT",
};

export const userInitialState = {
  user: {},
  stats: {},
  isLoading: false,
  userSocialLoader: false,
  userAuthedSocials: [],
};
