import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AppRoute from "./routes/route";
import { authProtectedRoutes, publicRoutes } from "./routes/";
import "./App.css";
// import "../src/assets/css/style.css"
import useLayout from "./layout/Layout";
// import "./sass/styles.css"

// import Profileconnect from "./components/Profileconnect";

function App() {
  useLayout();
  return (
    <Router>
      <Routes>
        {/* <Route path="/Profileconnect" element={<Profileconnect />} /> */}
        <Route path="/" element={<AppRoute />}>
          {authProtectedRoutes.map((route, idx) => (
            <Route exact path={route?.path} element={<route.component />} />
          ))}
        </Route>
        {publicRoutes.map((route, idx) => (
          <Route exact path={route?.path} element={<route.component />} />
        ))}
        <Route
          path="*"
          element={
            <div className="text-center d-flex justify-content-center align-items-center s100vh">
              {" "}
              <b> Not Found !! </b>
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
