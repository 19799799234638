import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { SettingValidation } from "./Validator";
import { useDispatch, useSelector } from "react-redux";
import { getSettingDataRequest, postSettingDataRequest } from "./Action";
import { AdminSettingSelctor } from "./Selector";
const Setting = () => {
  const dispatch = useDispatch();

  /* UserFormHook */
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(SettingValidation),
  });

  /* Selectors */
  const {getSettingData, sendsettingSuccess, getdataSuccess}=useSelector(AdminSettingSelctor)
  console.log(getSettingData, "34drtfgvh")
  /* States */
  /* Function */

  const onSubmitHandler = (formData) => {
    dispatch(postSettingDataRequest(formData));
    console.log(formData, errors, "4567yuhjkm");
   
   
    
  };
  /* UseState */

  useEffect(()=>{
    if(sendsettingSuccess==true){
      dispatch(getSettingDataRequest({}))
      reset({
        tax:getSettingData.tax,
        delivery_text:getSettingData.delivery_text,
        address:getSettingData.address,
        contact:getSettingData.contact,
        contact_gmail:getSettingData.contact_gmail,
        gmail:getSettingData.gmail,
        map_url:getSettingData.map_url,
        delivery_percent:getSettingData,
        max_range:getSettingData.max_range,
        min_range:getSettingData.min_range

      })
    }
  }, [sendsettingSuccess, ])


  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmitHandler)}>
        <Row>
          <Col>
            <Row className="styleform">
              <Col lg={4}>
                <Form.Group className="form-control">
                  <Form.Label>Gmail</Form.Label>
                  <Form.Control
                    type="email"
                    name="gmail"
                    {...register("gmail")}
                  />
                  {errors?.gmail && (
                    <p className="text-danger">{errors?.gmail?.message}</p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="form-control">
                  <Form.Label>Contact Gmail</Form.Label>
                  <Form.Control
                    type="email"
                    name="contact_gmail"
                    {...register("contact_gmail")}
                  />
                  {errors?.name && (
                    <p className="text-danger">{errors?.name?.message}</p>
                  )}
                  {errors?.contact_gmail && (
                    <p className="text-danger">
                      {errors?.contact_gmail?.message}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="form-control">
                  <Form.Label>Contact</Form.Label>
                  <Form.Control
                    type="text"
                    name="contact"
                    {...register("contact")}
                  />
                  {errors?.contact && (
                    <p className="text-danger">{errors?.contact?.message}</p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="styleform">
        
              <Col lg={4}>
                <Form.Group className="form-control">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    {...register("address")}
                  />
                  {errors?.address && (
                    <p className="text-danger">{errors?.address?.message}</p>
                  )}
                </Form.Group>
              </Col>
           
              <Col lg={4}>
                <Form.Group className="form-control">
                  <Form.Label>Delivery Text</Form.Label>
                  <Form.Control
                    type="text"
                    name="delivery_text"
                    {...register("delivery_text")}
                  />
                  {errors?.delivery_text && (
                    <p className="text-danger">
                      {errors?.delivery_text?.message}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="form-control">
                  <Form.Label>Delivery Percent</Form.Label>
                  <Form.Control
                    type="number"
                    name="delivery_percent"
                    {...register("delivery_percent")}
                  />
                  {errors?.delivery_percent && (
                    <p className="text-danger">
                      {errors?.delivery_percent?.message}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="styleform">
              <Col lg={4}>
                <Form.Group className="form-control">
                  <Form.Label>Tax</Form.Label>
                  <Form.Control type="number" name="tax" {...register("tax")} />
                  {errors?.tax && (
                    <p className="text-danger">{errors?.tax?.message}</p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="form-control">
                  <Form.Label>Map URL</Form.Label>
                  <Form.Control
                    type="text"
                    name="map_url"
                    {...register("map_url")}
                  />
                  {errors?.map_url && (
                    <p className="text-danger">{errors?.map_url?.message}</p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="form-control">
                  <Form.Label>Min Range</Form.Label>
                  <Form.Control
                    type="number"
                    name="min_range"
                    {...register("min_range")}
                  />
                  {errors?.map_url && (
                    <p className="text-danger">{errors?.map_url?.message}</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="styleform">
              <Col lg={4}>
                <Form.Group className="form-control">
                  <Form.Label>Max Range</Form.Label>
                  <Form.Control
                    type="number"
                    name="max_range"
                    {...register("max_range")}
                  />
                  {errors?.max_range && (
                    <p className="text-danger">{errors?.max_range?.message}</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
            <div className="d-flex aligin-item-end justify-content-end ">
          <Button type="submit">Submit</Button>
        </div>
            </Row>
          </Col>
        </Row>
     
      </Form>
    </div>
  );
};

export default Setting;
