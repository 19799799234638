import React, { useEffect, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CatAddSelector } from "./Selector";
import { getUserDataRequest } from "./Action";

import { useNavigate } from "react-router-dom";

const UserDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* Selectors */
  const { userData, userOrderData } = useSelector(CatAddSelector);

  console.log(userData, userOrderData, "fushdjfks");

  /* States */
 
  /* Function */

  /* UseEffect */
  useEffect(() => {
    dispatch(getUserDataRequest({}));
  }, []);
  return (
    <div>
      <h2>User Data</h2>

      <Card>
        <Table bordered>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Name</th>
              <th>Email </th>
              <th>Phone No</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {userData?.map((ele, ind) => {
              return (
                <tr key={ele.id}>
                  <th scope="row">{++ind}</th>
                  <td>{ele.name}</td>
                  <td>{ele.email}</td>
                  <td>{ele.mobile ?? "None"}</td>
                  <td>
                    <td>
                      <Button variant="success" onClick={() => navigate("/order/list/" + ele.id)}>
                        View Order
                      </Button>
                    </td>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
    </div>
  );
};

export default UserDetail;
