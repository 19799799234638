import { call, put, takeEvery } from "redux-saga/effects";
import { settingActionType } from "./Model";
import { getBannerApi, getSettingDetailApi, postBannerDetailApi, postSettingDetailApi } from "./Api";
import * as settingAction from "./Action";
import Swal from "sweetalert2";

function* handlepostData(action) {
  console.log(action.payload, "vgssbhjn");
  try {
    const response = yield call(postBannerDetailApi, action.payload);
    yield put(settingAction.postBannerManageSuccess(response));
    console.log(response, "5trgybb");
    Swal.fire({
      title: "SuccessFul",
      text: "Banner List Added Succesfully!",
      icon: "success",
    });
  } catch (e) {
    yield put(settingAction.postBannerManageFailure(e));
    Swal.fire({
      title: "Error",
      text: e?.response?.data?.message,
      icon: "error",
    });
  }
}

function* handlepostSettingData(action) {
  console.log(action.payload, "vgssbhjnsds");
  try {
    const response = yield call(postSettingDetailApi, action.payload);
    yield put(settingAction.postSettingDataSuccess(response));
    console.log(response, "5trgybb");
    Swal.fire({
      title: "SuccessFul",
      text: "Setting Data Add Succesfully!",
      icon: "success",
    });
  } catch (e) {
    yield put(settingAction.postSettingDataFailure(e));
    Swal.fire({
      title: "Error",
      text: e?.response?.data?.message,
      icon: "error",
    });
  }
}

export function* handlgetSettingData(action) {
  try {
    const response = yield call(getSettingDetailApi, action.payload);
    yield put(settingAction.getSettingDataSuccess(response));
    
  } catch (e) {
    yield put(settingAction.getSettingDataFailure(e));
  }
}

export function* handlegetBannerData(action) {
  try {
    const response = yield call(getBannerApi, action.payload);
    yield put(settingAction.getbannerDataSuccess(response));
    
  } catch (e) {
    yield put(settingAction.getbannerDataFailure(e));
  }
}
export function* AdminsettingSaga() {
  yield takeEvery(settingActionType.POSTBANNERDETAIL_REQUEST, handlepostData);
  yield takeEvery(settingActionType.POSTSETTINGDATA_REQUEST, handlepostSettingData);

  yield takeEvery(settingActionType.GETSETTINGDATA_REQUEST, handlgetSettingData);
  yield takeEvery(settingActionType.GETBANNERDATA_REQUEST, handlegetBannerData);


}
