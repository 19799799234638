import * as Yup from "yup";

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

export const signupValidator = Yup.object().shape({
  name:Yup.string().required("Name is Required"),
  email: Yup.string().required("Mail is Required"),
  password: Yup.string()
    .matches(passwordRules, {
      message:
        "Please create a stronger password(min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit)",
    })
    .required("Required"),
});
export const signinValidator = Yup.object().shape({
  email: Yup.string().required("Mail is Required"),
  password: Yup.string().required("Required"),
});
export const AddGroupValidator = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
});

export const LinkedInValidator = Yup.object().shape({
  social_access_token: Yup.string().required("AccessToken is Required"),
  provided_user_id: Yup.string().required("Organisation Id is Required"),
});

export const profileSettingValidator = Yup.object().shape({
  company_name: Yup.string().required("Company Name is Required"),
  contact_number: Yup.string().required("Contact Number is Required"),
  mail_id: Yup.string().required("Mail id is Required"),
});

export const AccountSettingValidator = Yup.object().shape({
  group_id: Yup.number().required("Group is Required"),
});
