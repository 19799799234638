import { action } from "typesafe-actions";
import { MenuActionTypes } from "./menu.model";

export const menuChangePlacement = (e) =>
  action(MenuActionTypes.MENUCHANGEPLACEMENT, e);

export const menuChangePlacementStatus = (e) =>
  action(MenuActionTypes.MENUCHANGEPLACEMENTSTATUS, e);

export const menuChangeBehaviour = (e) =>
  action(MenuActionTypes.MENUCHANGBEHAVIOUR, e);

export const menuChangeBehaviourStatus = (e) =>
  action(MenuActionTypes.MENUCHANGBEHAVIOURSTATUS, e);

export const menuChangePinButtonEnable = (e) =>
  action(MenuActionTypes.MENUCHANGEPINBUTTONENABLE, e);

export const menuChangeAttrMenuAnimate = (e) =>
  action(MenuActionTypes.MENUCHANGEATTRMENUANIMATE, e);

export const menuChangeAttrMobile = (e) =>
  action(MenuActionTypes.MENUCHANGEATTRMOBILE, e);

export const menuChangeCollapseAll = (e) =>
  action(MenuActionTypes.MENUCHANGECOLLAPSEALL, e);

export const menuChangeNavClasses = (e) =>
  action(MenuActionTypes.MENUCHANGENAVCLASSES, e);

export const menuChangeBreakpoints = (e) =>
  action(MenuActionTypes.MENUCHANGEBREAKPOINTS, e);

export const menuResetBreakpoints = (e) =>
  action(MenuActionTypes.MENURESETBREAKPOINTS, e);

export const menuChangeUseSidebar = (e) =>
  action(MenuActionTypes.MENUCHANGEUSERSIDEBAR, e);

export const menuResetUseSidebar = (e) =>
  action(MenuActionTypes.MENURESETUSERSIDEBAR, e);

export const menuChangeMenuPadding = (e) =>
  action(MenuActionTypes.MENUCHANGEMENUPADDING, e);
