import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { ManageBannerValidator } from "./Validator";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { getbannerDataRequest, postBannerManageRequest } from "./Action";
import { AdminSettingSelctor } from "./Selector";

const BannerManage = () => {
  const dispatch = useDispatch();
  const id =localStorage.getItem("id")
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(ManageBannerValidator),
  });

  /* Selectors */
const {bannerData, postMessage}= useSelector(AdminSettingSelctor)
console.log(bannerData, "yuijk6gh")
  /* States */
  const [startDate, setStartDate] = useState();
  const [startDate1, setStartDate1] = useState();
  const [image, setImage] = useState("");
  console.log(image, "43rmkl");
  const [errorMsg, seterrorMsg] = useState({
    doj: false,
    ele: false,
    img: false,
  });
  /* Function */
  const onSubmitHandler = (formData) => {
    if(bannerData?.id !==0){
      formData.id=bannerData?.id;
    }
    formData.image = image;
    delete formData.data_from;
    delete formData.data_to;
    formData.date_from = dayjs(startDate).format("DD/MM/YYYY");
    formData.date_to = dayjs(startDate1).format("DD/MM/YYYY");
    // formData.date_from=startDate;
    // formData.date_to=startDate1;
    console.log(formData, "23dertcfv");
    dispatch(postBannerManageRequest(formData));
  };

  /* useEffect */
  useEffect(() => {
    dispatch(getbannerDataRequest())
    reset({
      "name":bannerData?.name,
      "content":bannerData?.content,
      "description":bannerData?.description,
      "quote":bannerData?.quote
    })
  }, []);
  useEffect(() => {
    if (postMessage) {
      dispatch(getbannerDataRequest());
      reset({
        "name":bannerData?.name,
        "content":bannerData?.content,
        "description":bannerData?.description,
        "quote":bannerData?.quote
      })
    }},[postMessage])

  return (
    <div>
      <Row>
        <Col lg={12}>
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div class="p-2 bg-light border">
              <Row style={{ padding: "15px" }}>
                <Col lg={6}>
                  <Form.Group as={Col} lg={6} className="form-control">
                    <Form.Label className="label-style">Name</Form.Label>
                    <Form.Control type="text" {...register("name")} />
                    {errors?.name && (
                      <p className="text-danger">{errors?.name?.message}</p>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group as={Col} lg={6} className="form-control">
                    <Form.Label className="label-style">Quote</Form.Label>
                    <Form.Control type="text" {...register("quote")} />
                    {errors?.quote && (
                      <p className="text-danger">{errors?.quote?.message}</p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row style={{ padding: "15px" }}>
                <Col lg={6}>
                  <Form.Group as={Col} lg={6} className="form-control">
                    <Form.Label className="label-style">Content</Form.Label>
                    <Form.Control as="textarea" {...register("content")} />
                    {errors?.content && (
                      <p className="text-danger">{errors?.content?.message}</p>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group as={Col} lg={6} className="form-control">
                    <Form.Label className="label-style">Description</Form.Label>
                    <Form.Control
                      t
                      as="textarea"
                      {...register("description")}
                    />
                    {errors?.description && (
                      <p className="text-danger">
                        {errors?.description?.message}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row style={{ padding: "15px" }}>
                <Col lg={6}>
                  <Form.Group as={Col} lg={6} className="form-control">
                    <Form.Label className="label-style">Date-From</Form.Label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                    {errorMsg?.doj && (
                      <p className="text-danger"> Date From is Required</p>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group as={Col} lg={6} className="form-control">
                    <Form.Label className="label-style">Date-To</Form.Label>
                    <DatePicker
                      selected={startDate1}
                      onChange={(date) => setStartDate1(date)}
                    />
                    {errorMsg?.ele && (
                      <p className="text-danger"> Date To is Required</p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row style={{ padding: "15px" }}>
                <Col lg={6}>
                  <Form.Group as={Col} lg={6} className="form-control">
                    <Form.Label className="label-style">Image</Form.Label>
                    <Form.Control
                      type="file"
                      accept="image/*"
                      name="profile_img"
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                    {errorMsg?.img && (
                      <p className="text-danger"> Image Required</p>
                    )}
                  </Form.Group>
                </Col>
                <Form.Group
                  as={Col}
                  lg={2}
                  className="d-flex align-items-center justify-content-center"
                >
                  <Button
                    type="submit"
                    onClick={() => {
                      if (!startDate || !startDate1 || !image) {
                        seterrorMsg({
                          doj: !startDate,
                          ele: !startDate1,
                          img: !image,
                        });
                      } else {
                        seterrorMsg({
                          doj: false,
                          ele: false,
                          img: false,
                        });
                      }
                    }}
                  >
                    Submit
                  </Button>
                </Form.Group>
              </Row>
            </div>
          </form>
        </Col>
      </Row>
    </div>
  );
};

export default BannerManage;
