import { InventeryManageActionType, InventryInitailState } from "./Model";

const reducer = (state = InventryInitailState, action) => {
  switch (action.type) {
    case InventeryManageActionType.POSTMANAGEINVENTRY_REQUEST:
      return {
        ...state,
        loading: true,
        postSuccess:false,
      };

    case InventeryManageActionType.POSTMANAGEINVENTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        postSuccess:true,

      };

    case InventeryManageActionType.POSTMANAGEINVENTRY_FAILURE:
      return {
        ...state,
        postSuccess:false,
        loading: false,
      };

    case InventeryManageActionType.GETNVENTRYDATA_REQUEST:
      return {
        ...state,
        getLoad: true,
      };
    case InventeryManageActionType.GETNVENTRYDATA_SUCCESS:
      return {
        ...state,
        getLoad: false,
        getInventData: action.payload?.data?.datas.data,
      };

    case InventeryManageActionType.GETNVENTRYDATA_FAILURE:
      return {
        ...state,
        getLoad: false,
      };
      case InventeryManageActionType.UPDATEINVENTRYDATA_REQUEST:
      return {
        ...state,
        updateLoad: true,
        updateSuccess:false,
      };
    case InventeryManageActionType.UPDATEINVENTRYDATA_SUCCESS:
      return {
        ...state,
        updateLoad: false,
        updateSuccess:true,
      };

    case InventeryManageActionType.UPDATEINVENTRYDATA_FAILURE:
      return {
        ...state,
        updateLoad: false,
        updateSuccess:false,
      };

    default:
      return state;
  }
};
export { reducer as InventryReducer };
