export const InventeryManageActionType={
    POSTMANAGEINVENTRY_REQUEST: "@@inventry/POSTMANAGEINVENTRY_REQUEST",
    POSTMANAGEINVENTRY_SUCCESS: "@@inventry/POSTMANAGEINVENTRY_SUCCESS",
    POSTMANAGEINVENTRY_FAILURE: "@@inventry/POSTMANAGEINVENTRY_FAILURE",

    GETNVENTRYDATA_REQUEST: "@@inventry/GETNVENTRYDATA_REQUEST",
    GETNVENTRYDATA_SUCCESS: "@@inventry/GETNVENTRYDATA_SUCCESS",
    GETNVENTRYDATA_FAILURE: "@@inventry/GETNVENTRYDATA_FAILURE",
    
    UPDATEINVENTRYDATA_REQUEST: "@@inventry/UPDATEINVENTRYDATA_REQUEST",
    UPDATEINVENTRYDATA_SUCCESS: "@@inventry/UPDATEINVENTRYDATA_SUCCESS",
    UPDATEINVENTRYDATA_FAILURE: "@@inventry/UPDATEINVENTRYDATA_FAILURE",
}

export const InventryInitailState={
    loading:false,
    postSuccess:false,
    getLoad:false,
    getInventData:[],
    updateLoad:false,
    updateSuccess:false,
}