// import { signInWithPopup, signInWithEmailAndPassword } from 'firebase/auth';
import { call, put, takeEvery } from "redux-saga/effects";
import { decryptdata, encryptdata } from "../../utils/encrypt&decrypt";
import Swal from "sweetalert2";
// import { defaultAuth, defaultProvider } from '../../helpers/firebase/config';
import * as AuthActions from "./auth.action";
import { AuthActionTypes } from "./auth.model";
import { signIn, signUp, VerifyMail } from "./auth.api";
import { secretKey } from "../../services/config";

export function* handleSignup(action) {
  try {
    console.log(action, "sdfsd action");
    const response = yield call(signUp, action.payload);
    yield put(AuthActions.signUpUserSuccess(response));

    console.log(response, "sdfsd");
  } catch (error) {
    console.log(error, "ihdiufhd");
    yield put(AuthActions.signUpUserFailure(error));
  }
}

export function* handleLogin(action) {
  try {
    console.log(action.payload, "12login action");
    // const { payload } = action;
    // const formData = {
    //   key: encryptdata(payload.email, secretKey),
    //   pass: encryptdata(payload.password, secretKey),
    // };
    const response = yield call(signIn, action.payload);
    yield put(AuthActions.loginUserSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(AuthActions.loginUserFailure(error));
  }
}

export function* verifyMail({ payload }) {
  try {
    const formdata = {
      pass1: decryptdata(payload?.key, secretKey),
      pass2: decryptdata(payload?.pass, secretKey),
    };
    const response = yield call(VerifyMail, formdata);
    yield put(AuthActions.verifyMailSuccess(response));
    console.log(response, "sdfsd");
  } catch (error) {
    yield put(AuthActions.verifyMailFailure(error));
    console.log(error, "ihdiufhd");
  }
}
export function* AuthSaga() {
  yield takeEvery(AuthActionTypes.SIGNUP_USER_REQUEST, handleSignup);
  yield takeEvery(AuthActionTypes.LOGIN_USER_REQUEST, handleLogin);
  yield takeEvery(AuthActionTypes.VERIFY_MAIL_REQUEST, verifyMail);
}
