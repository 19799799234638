import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Form, Row, Col, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { InventrySelector } from "./Selector";
import { getInventryDataRequest, postInventryDataRequest, updateInventryDataRequest } from "./Action";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { InventryCreateValidator } from "../AdminPanel/Validator";
import { getProductRequest } from "../AdminPanel/Action";
import { CatAddSelector } from "../AdminPanel/Selector";
import { selectDefault } from "../../utils/Constants";

const InventoryManage = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(InventryCreateValidator),
  });
  /* Selectors */
  const { getInventData,postSuccess,updateSuccess } = useSelector(InventrySelector);
  const { getProductData } = useSelector(CatAddSelector);
  console.log(getInventData, "w345ftygui");

  /* States */
  const [id, setId] = useState(0);
  console.log(id, "tyhbj");
  /* Functions */
  const onSubmitHandler = (formData) => {
    delete formData.unit_qty;
    if (formData.outward_qty == "" ) {
      delete formData.outward_qty;
    }
    if(formData.inward_qty==""){
      delete formData.inward_qty
    }
    console.log(formData, errors, "5tygbhjk");
    formData.unit_type=  100;
    if(id==0){

      dispatch(postInventryDataRequest(formData));
    }else{
      const payload={
        formData,id
      }
      dispatch(updateInventryDataRequest(payload))
    }
  };
  const handleEdit = (val) => {
    console.log(val, "y6u")
    setId(val?.id);
    reset({
      current_qty: val?.product_id,
      inward_qty: "",
      opening_qty: val?.opening_qty,
      product_id: val?.product_id,
      unit_type:val?.unit_type,
      outward_qty:""
    });
  };

  const productVal = watch("product_id");

  /* useEffect */
  useEffect(() => {
    dispatch(getInventryDataRequest());
    dispatch(getProductRequest({}));
    setValue("product_id", selectDefault);
  }, [selectDefault]);
  useEffect(() => {
    const product = getProductData?.find((ele) => ele.id === parseInt(productVal));
    console.log(product, "ufjsd")
    if (product) {
      console.log(product, "rtyghbj")
      setValue("unit_type", product.unit_type);
    }
  }, [getProductData, productVal, setValue]);
  useEffect(()=>{
if(postSuccess || updateSuccess){
  dispatch(getInventryDataRequest());
  setId(0)
 reset({
  current_qty:"",
  inward_qty:"0",
  opening_qty:"",
  product_id:"",
  unit_type:"",
  outward_qty:"0"
 })
 setValue("product_id", selectDefault);
}
  }, [postSuccess,updateSuccess])
  return (
    <div>
      <h2>Inventory Management</h2>

      <Row>
        <Col lg={12}>
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div class="p-2 bg-light border ">
              <Row className="styleform">
                <Col lg={4}>
                  <Form.Group as={Col} lg={6} className="form-control">
                    <Form.Label className="label-style">
                      Product Name
                    </Form.Label>
                    <Form.Control as="select" {...register("product_id")}
                    disabled={id!=0}
                    >
                      <option value={selectDefault} disabled>
                        SELECT
                      </option>
                      {getProductData?.map((ele) => {
                        return <option value={ele.id}>{ele.name}</option>;
                      })}
                    </Form.Control>
                    {errors?.product_id && (
                      <p className="text-danger">
                        {errors?.product_id?.message}
                      </p>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group as={Col} lg={4} className="form-control">
                    <Form.Label className="label-style" >
                      Opening Quantity
                    </Form.Label>
                    <Form.Control type="number" {...register("opening_qty")} 
                    disabled={id!=0}
                    />
                    {errors?.opening_qty && (
                      <p className="text-danger">
                        {errors?.opening_qty?.message}
                      </p>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group as={Col} lg={4} className="form-control">
                    <Form.Label className="label-style">
                      Current Quantity
                    </Form.Label>
                    <Form.Control type="number" {...register("current_qty")} 
                    disabled={id!=0}
                    />
                    {errors?.current_qty && (
                      <p className="text-danger">
                        {errors?.current_qty?.message}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="styleform">
                <Col lg={4}>
                  <Form.Group as={Col} lg={4} className="form-control">
                    <Form.Label className="label-style">
                      Inward Quantity
                    </Form.Label>
                    <Form.Control type="number" {...register("inward_qty")} />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group as={Col} lg={6} className="form-control">
                    <Form.Label className="label-style">
                      Outward Quantity
                    </Form.Label>
                    <Form.Control
                      type="number"
                      {...register("outward_qty")}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group as={Col} lg={4} className="form-control" style={{display:"none"}}>
                    <Form.Label className="label-style">Grams</Form.Label>
                    <Form.Control type="number" {...register("unit_type")} readOnly/>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Form.Group
                  as={Col}
                  lg={12}
                  className="d-flex justify-content-end"
                >
                  <Button type="submit">{id == 0 ? "Submit" : "Update"}</Button>
                </Form.Group>
              </Row>
            </div>
          </form>
        </Col>
        <Col>
          <h2>List</h2>
          <Card body className="mb-5 mt-2 d-flex">
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  {/* <th>Grams </th> */}
                  <th>Product Name</th>
                  <th>Opening Quantity</th>
                  <th>Current Quantity</th>
                  {/* <th>Inward Quantity</th> */}
                  {/* <th>Total Volume</th> */}
                  {/* <th>Outward Quantity</th> */}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {getInventData?.map((ele, ind) => {
                  return (
                    <tr>
                      <td>{ind + 1}</td>
                      <td>{ele.products?.name}</td>
                      {/* <td>{ele?.unit_type} GM</td> */}
                      <td>{ele.opening_qty}</td>
                      <td>{ele.current_qty}</td>
                      {/* <td>{ele.inward_qty}</td> */}
                      {/* <td>{ele?.total_qty} GM</td> */}
                      {/* <td>
                        {ele?.outward_qty !== null
                          ? ele?.outward_qty
                          : "- - - -"}
                      </td> */}
                      <td>
                        <Button variant="info" onClick={() => handleEdit(ele)}>
                          Edit
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default InventoryManage;
