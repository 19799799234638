import React, { useEffect, useState } from "react";
import { Row, Col, Card, ProgressBar, Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCardDataRequest, getDashBoardDataRequest } from "./Action";
import { CatAddSelector } from "./Selector";
import dayjs from "dayjs";
import { BarChart } from "@mui/x-charts";
import { RiUserStarFill } from "react-icons/ri";
import { AiFillProduct } from "react-icons/ai";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  CiDeliveryTruck,
  CiUser,
  CiBadgeDollar,
  CiShoppingCart,
  CiAlignBottom,
  CiBoxList,
  CiCalendar
} from "react-icons/ci";
import {
  getMonthNames,
  getWeekNumbers,
  getYearRange,
} from "../../utils/Constants";
// import CsLineIcons from 'cs-line-icons/CsLineIcons';
const DashBoard = () => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const dispatch = useDispatch();
  const { cardData, dashData } = useSelector(CatAddSelector);
  console.log(dashData, "76ygubhj");

  /* states */
  const [filter, setFilter] = useState("monthly");
  const [selectedYear, setSelectedYear] = useState(dayjs().year());

  let data = [
    {
      label: "Medical",
      value: 55,
      color: "rgba(0, 43, 73, 1)",
      cutout: "50%",
    },
    {
      label: "Fitness",
      value: 15,
      color: "rgba(0, 103, 160, 1)",
      cutout: "50%",
    },
    {
      label: "Baby",
      value: 80,
      color: "rgba(83, 217, 217, 1)",
      cutout: "50%",
    },
  ];

  const options = {
    plugins: {
      responsive: true,
    },
    cutout: data.map((item) => item.cutout),
  };

  const finalData = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        data: data.map((item) => Math.round(item.value)),
        backgroundColor: data.map((item) => item.color),
        borderColor: data.map((item) => item.color),
        borderWidth: 1,
        dataVisibility: new Array(data.length).fill(true),
      },
    ],
  };

  /* Function */
  const transformData = (data, filterType) => {
    if (filterType == "monthly") {
      const months = getMonthNames();
      return months.map((month) => {
        const entry = data.find((d) => d.label === `${month} ${selectedYear}`);
        console.log(entry, "56tyghbn");

        return entry ? parseInt(entry.total_sale_value) : 0;
      });
    } else if (filterType === "yearly") {
      const years = getYearRange();
      return years.map((year) => {
        const entry = data.find((d) => d.label === year);
        return entry ? entry.total_sale_value : 0;
      });
    } else if (filterType === "weekly") {
      const weeks = getWeekNumbers();
      return weeks.map((week) => {
        const entry = data.find((e) => e.label === `${week} (${selectedYear})`);
        return entry ? entry.total_sale_value : 0;
      });
    }
  };

  const xAxisData =
    filter === "weekly"
      ? getWeekNumbers()
      : filter === "monthly"
      ? getMonthNames()
      : getYearRange();

  const barChartData = transformData(dashData, filter);
  console.log(barChartData, xAxisData, "serfcgh");
  /* useEffect */
  useEffect(() => {
    dispatch(getCardDataRequest());
  }, []);
  useEffect(() => {
    const payload = {
      period: filter,
    };
    dispatch(getDashBoardDataRequest(payload));
  }, [filter, selectedYear]);
  return (
    <div>
      <div>
        <h2>DashBoard</h2>
      </div>

      <Row className="g-2">
        <Col sm="3">
          <Card
            className="hover-scale-up cursor-pointer"
            style={{ backgroundColor: "#c4def6" }}
          >
            <Card.Body className="h-100 py-3 align-items-center">
              <Row className="g-0 h-100 align-items-center">
                <Col xs="auto" className="pe-3">
                  <div className="sh-11 sw-11 rounded-xl d-flex justify-content-center align-items-center">
                    {/* <CsLineIcons icon="navigate-diagonal" className="text-white" /> */}
                    <CiUser size={80} />
                  </div>
                </Col>
                <Col>
                  <Row className="gx-2 d-flex align-content-center">
                    <Col xs="12" className="col-12 d-flex">
                      <div className="d-flex align-items-center ">
                        Total Customers
                      </div>
                    </Col>
                    <Col xl="auto" className="col-12">
                      <div
                        className="cta-2 text-primary"
                        style={{ fontSize: "40px" }}
                      >
                        {cardData?.total_orders}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="3">
          <Card
            className="hover-scale-up cursor-pointer"
            style={{ backgroundColor: "#bcede8" }}
          >
            <Card.Body className="h-100 py-3 align-items-center">
              <Row className="g-0 h-100 align-items-center">
                <Col xs="auto" className="pe-3">
                  <div className="sh-11 sw-11 rounded-xl d-flex justify-content-center align-items-center">
                    {/* <CsLineIcons icon="sync-horizontal" className="text-white" /> */}
                    <CiShoppingCart size={80} />
                  </div>
                </Col>
                <Col>
                  <Row className="gx-2 d-flex align-content-center">
                    <Col xs="12" className="col-12 d-flex">
                      <div className="d-flex align-items-center ">
                        Total Products
                      </div>
                    </Col>
                    <Col xl="auto" className="col-12">
                      <div
                        className="cta-2 text-primary "
                        style={{ fontSize: "40px" }}
                      >
                        {cardData?.total_products}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="3">
          <Card
            className="hover-scale-up cursor-pointer"
            style={{ backgroundColor: "#d6cce7" }}
          >
            <Card.Body className="h-100 py-3 align-items-center">
              <Row className="g-0 h-100 align-items-center">
                <Col xs="auto" className="pe-3">
                  <div className="sh-11 sw-11 rounded-xl d-flex justify-content-center align-items-center">
                    {/* <CsLineIcons icon="sync-horizontal" className="text-white" /> */}
                    <CiBoxList size={80} />
                  </div>
                </Col>
                <Col>
                  <Row className="gx-2 d-flex align-content-center">
                    <Col xs="12" className="col-12 d-flex">
                      <div className="d-flex align-items-center ">
                        Total Categories
                      </div>
                    </Col>
                    <Col xl="auto" className="col-12">
                      <div
                        className="cta-2 text-primary "
                        style={{ fontSize: "40px" }}
                      >
                        {4}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="3">
          <Card
            className="hover-scale-up cursor-pointer"
            style={{ backgroundColor: "#ebc3c0" }}
          >
            <Card.Body className="h-100 py-3 align-items-center">
              <Row className="g-0 h-100 align-items-center">
                <Col xs="auto" className="pe-3">
                  <div className="sh-11 sw-11 rounded-xl d-flex justify-content-center align-items-center">
                    {/* <CsLineIcons icon="sync-horizontal" className="text-white" /> */}
                    <CiAlignBottom size={80} />
                  </div>
                </Col>
                <Col>
                  <Row className="gx-2 d-flex align-content-center">
                    <Col xs="12" className="col-12 d-flex">
                      <div className="d-flex align-items-center ">
                        Total Site Visitors
                      </div>
                    </Col>
                    <Col xl="auto" className="col-12">
                      <div
                        className="cta-2 text-primary "
                        style={{ fontSize: "40px" }}
                      >
                        400
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="3">
          <Card
            className="hover-scale-up cursor-pointer"
            style={{ backgroundColor: "#f6e8bf" }}
          >
            <Card.Body className="h-100 py-3 align-items-center">
              <Row className="g-0 h-100 align-items-center">
                <Col xs="auto" className="pe-3">
                  <div className="sh-11 sw-11 rounded-xl d-flex justify-content-center align-items-center">
                    {/* <CsLineIcons icon="check" className="text-white" /> */}
                    <CiCalendar size={30} /><CiDeliveryTruck size={80} />
                  </div>
                </Col>
                <Col>
                  <Row className="gx-2 d-flex align-content-center">
                    <Col xs="12" className="col-12 d-flex">
                      <div className="d-flex align-items-center ">
                        Today Orders
                      </div>
                    </Col>
                    <Col xl="auto" className="col-12">
                      <div
                        className="cta-2 text-primary"
                        style={{ fontSize: "40px" }}
                      >
                        5
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="3">
          <Card
            className="hover-scale-up cursor-pointer"
            style={{ backgroundColor: "#ddf6c1" }}
          >
            <Card.Body className="h-100 py-3 align-items-center ">
              <Row className="g-0 h-100 align-items-center">
                <Col xs="auto" className="pe-3">
                  <div className="sh-11 sw-11 rounded-xl d-flex justify-content-center align-items-center">
                    {/* <CsLineIcons icon="alarm" className="text-white" /> */}
                    <CiCalendar size={30} /><CiBadgeDollar size={80} />
                  </div>
                </Col>
                <Col>
                  <Row className="gx-2 d-flex align-content-center">
                    <Col xs="12" className="col-12 d-flex">
                      <div className="d-flex align-items-center ">
                        Today Revenue
                      </div>
                    </Col>
                    <Col xl="auto" className="col-12">
                      <div
                        className="cta-2 text-primary"
                        style={{ fontSize: "40px" }}
                      >
                        {cardData?.total_revenue || 1000.0}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="3">
          <Card
            className="hover-scale-up cursor-pointer"
            style={{ backgroundColor: "#f6e8bf" }}
          >
            <Card.Body className="h-100 py-3 align-items-center">
              <Row className="g-0 h-100 align-items-center">
                <Col xs="auto" className="pe-3">
                  <div className="sh-11 sw-11 rounded-xl d-flex justify-content-center align-items-center">
                    {/* <CsLineIcons icon="check" className="text-white" /> */}
                    <CiDeliveryTruck size={80} />
                  </div>
                </Col>
                <Col>
                  <Row className="gx-2 d-flex align-content-center">
                    <Col xs="12" className="col-12 d-flex">
                      <div className="d-flex align-items-center ">
                        Total Orders
                      </div>
                    </Col>
                    <Col xl="auto" className="col-12">
                      <div
                        className="cta-2 text-primary"
                        style={{ fontSize: "40px" }}
                      >
                        {cardData?.total_orders}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="3">
          <Card
            className="hover-scale-up cursor-pointer"
            style={{ backgroundColor: "#ddf6c1" }}
          >
            <Card.Body className="h-100 py-3 align-items-center ">
              <Row className="g-0 h-100 align-items-center">
                <Col xs="auto" className="pe-3">
                  <div className="sh-11 sw-11 rounded-xl d-flex justify-content-center align-items-center">
                    {/* <CsLineIcons icon="alarm" className="text-white" /> */}
                    <CiBadgeDollar size={80} />
                  </div>
                </Col>
                <Col>
                  <Row className="gx-2 d-flex align-content-center">
                    <Col xs="12" className="col-12 d-flex">
                      <div className="d-flex align-items-center ">
                        Total Revenue
                      </div>
                    </Col>
                    <Col xl="auto" className="col-12">
                      <div
                        className="cta-2 text-primary"
                        style={{ fontSize: "40px" }}
                      >
                        {cardData?.total_revenue || 10000.0}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <Card className="mt-5">
        <div className="row g-4 py-5 px-5 row-cols-1 row-cols-lg-3">
          <div className="col d-flex align-items-start">
            <div className="rounded-circle text-body-emphasis iconscls"></div>
            <div className="paradiv">
              <p className="paras"> Revenue</p>
              <p className="paras1"> {} </p>
            </div>
            <div className="vi"></div>
          </div>
          <div className="col d-flex align-items-start">
            <div className="rounded-circle text-body-emphasis iconscls"></div>
            <div className="paradiv">
              <p className="paras">Profit & Loss</p>
              <p className="paras1">{} </p>
            </div>
            <div className="vi2"></div>
          </div>
          <div className="col d-flex align-items-start">
            <div className="rounded-circle text-body-emphasis iconscls"></div>
            <div className="paradiv">
              <p className="paras">Profit Value</p>
              <p className="paras1"> {} </p>
            </div>
          </div>
        </div>
      </Card> */}
      <Row>
        <Col sm={9}>
          <div className="mt-5">
            <div>
              <h2 className="small-title">Revenue Chart</h2>
              {/* <h2 className="text-muted">Revenue Chart</h2> */}
            </div>
            <div className="row justify-content-end">
              <div className="col-2">
                <label htmlFor="filterSelect">Default Filter: </label>
                <select
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  className="form-select"
                >
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                </select>
              </div>

              <div className="col-2 ">
                <label htmlFor="yearSelect">Select Year: </label>
                <select
                  id="yearSelect"
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(Number(e.target.value))}
                  className="form-select"
                >
                  {getYearRange().map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <BarChart
              series={[{ data: barChartData }]}
              height={290}
              xAxis={[
                {
                  data: xAxisData,
                  scaleType: "band",
                },
              ]}
              // margin={{ top: 20, bottom: 20, left: 60, right: 40 }}
            />
          </div>
        </Col>
        <Col sm={3}>
          <div className="mt-5">
            <h2 className="small-title">Category Based Sales</h2>{" "}
            <Doughnut data={finalData} options={options} />
          </div>
        </Col>
      </Row>
      <Row>

{/* Spendings Start */}
<Col lg="3">
  <div className="mt-5">
    <h2 className="small-title">Top Products</h2>
    <Card className="h-100-card">
      <Card.Body>
        <Row className="g-0 sh-10 sh-sm-7 mb-2">
          <Col xs="auto">
            <img
              src="https://digital-garage.in/demo/madar/site-1/assets/images/product/img_5.jpg"
              className="card-img rounded-xl sh-6 sw-6"
              alt="thumb"
            />
          </Col>
          <Col>
            <div className="d-flex flex-column flex-sm-row ps-4 h-100 align-items-sm-center justify-content-sm-between">
              <div className="d-flex flex-column mb-2 mb-sm-0">
                <div>Knee Cap</div>
                <div className="text-small text-muted">Medical</div>
              </div>
              <div className="d-flex">
                <Button variant="outline-primary ms-1" size="sm">
                  300
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="g-0 sh-10 sh-sm-7 mb-2">
          <Col xs="auto">
            <img
              src="https://digital-garage.in/demo/madar/site-3/images/photoshop-2.jpg"
              className="card-img rounded-xl sh-6 sw-6"
              alt="thumb"
            />
          </Col>
          <Col>
            <div className="d-flex flex-column flex-sm-row ps-4 h-100 align-items-sm-center justify-content-sm-between">
              <div className="d-flex flex-column mb-2 mb-sm-0">
                <div>Face Mask</div>
                <div className="text-small text-muted">Medical</div>
              </div>
              <div className="d-flex">
                <Button variant="outline-primary ms-1" size="sm">
                  200
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="g-0 sh-10 sh-sm-7 mb-2">
          <Col xs="auto">
            <img
              src="https://digital-garage.in/demo/madar/site-1/assets/images/slider/baby-stroller.png"
              className="card-img rounded-xl sh-6 sw-6"
              alt="thumb"
            />
          </Col>
          <Col>
            <div className="d-flex flex-column flex-sm-row ps-4 h-100 align-items-sm-center justify-content-sm-between">
              <div className="d-flex flex-column mb-2 mb-sm-0">
                <div>Stroller</div>
                <div className="text-small text-muted">Baby</div>
              </div>
              <div className="d-flex">
                <Button variant="outline-primary ms-1" size="sm">
                  100
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="g-0 sh-10 sh-sm-7 mb-2">
          <Col xs="auto">
            <img
              src="https://digital-garage.in/demo/madar/site-1/assets/images/slider/cycle.png"
              className="card-img rounded-xl sh-6 sw-6"
              alt="thumb"
            />
          </Col>
          <Col>
            <div className="d-flex flex-column flex-sm-row ps-4 h-100 align-items-sm-center justify-content-sm-between">
              <div className="d-flex flex-column mb-2 mb-sm-0">
                <div>Fitness Cycle</div>
                <div className="text-small text-muted">Fitness</div>
              </div>
              <div className="d-flex">
                <Button variant="outline-primary ms-1" size="sm">
                  50
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="g-0 sh-10 sh-sm-7 mb-2">
          <Col xs="auto">
            <img
              src="https://digital-garage.in/demo/madar/site-1/assets/images/slider/purll.png"
              className="card-img rounded-xl sh-6 sw-6"
              alt="thumb"
            />
          </Col>
          <Col>
            <div className="d-flex flex-column flex-sm-row ps-4 h-100 align-items-sm-center justify-content-sm-between">
              <div className="d-flex flex-column mb-2 mb-sm-0">
                <div>Hand Sanitizer</div>
                <div className="text-small text-muted">Medical</div>
              </div>
              <div className="d-flex">
                <Button variant="outline-primary ms-1" size="sm">
                  80
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  </div>
</Col>
{/* Spendings End */}
        <Col sm={9}>
          <div className="mt-5">
            <h2 className="small-title">Today Orders</h2>{" "}
            <Card className="p-3">
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Order No</th>
                  <th>Name</th>
                  <th>Products </th>
                  <th>Total</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>ORD-08761</td>
                  <td>Abdul</td>
                  <td>Hand Sanitizer - 1 Quantity, Fitness Cycle -1 Quantity, Knee Cap-2 Quantity</td>
                  <td>SAR 200</td>
                  <td><Button  variant="outline-primary ms-1" size="sm"> View Order </Button></td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>ORD-08762</td>
                  <td>Ameer</td>
                  <td>Hand Sanitizer - 1 Quantity</td>
                  <td>SAR 300</td>
                  <td><Button  variant="outline-primary ms-1" size="sm"> View Order </Button></td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>ORD-08751</td>
                  <td>Abdul</td>
                  <td>Fitness Cycle -1, Knee Cap-2</td>
                  <td>SAR 100</td>
                  <td><Button  variant="outline-primary ms-1" size="sm"> View Order </Button></td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>ORD-08762</td>
                  <td>Abdul</td>
                  <td>Hand Sanitizer - 1 Quantity, Fitness Cycle -1, Knee Cap-2</td>
                  <td>SAR 200</td>
                  <td><Button  variant="outline-primary ms-1" size="sm"> View Order </Button></td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>ORD-98762</td>
                  <td>Abdul</td>
                  <td>Hand Sanitizer - 1 Quantity, Fitness Cycle -1, Knee Cap-2</td>
                  <td>SAR 200</td>
                  <td><Button  variant="outline-primary ms-1" size="sm"> View Order </Button></td>
                </tr>
              </tbody>
            </Table>
          </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DashBoard;
