import { DEFAULT_SETTINGS } from "../../utils/config";

export const getComputedValues = () => {
  const rootStyle = window.getComputedStyle(document.body);
  return {
    primary: rootStyle.getPropertyValue("--primary").trim(),
    secondary: rootStyle.getPropertyValue("--secondary").trim(),
    tertiary: rootStyle.getPropertyValue("--tertiary").trim(),
    quaternary: rootStyle.getPropertyValue("--quaternary").trim(),
    body: rootStyle.getPropertyValue("--body").trim(),
    alternate: rootStyle.getPropertyValue("--alternate").trim(),
    lightText: rootStyle.getPropertyValue("--light-text").trim(),
    warning: rootStyle.getPropertyValue("--warning").trim(),
    danger: rootStyle.getPropertyValue("--danger").trim(),
    success: rootStyle.getPropertyValue("--success").trim(),
    info: rootStyle.getPropertyValue("--info").trim(),

    font: rootStyle.getPropertyValue("--font").trim(),
    fontHeading: rootStyle.getPropertyValue("--font-heading").trim(),

    background: rootStyle.getPropertyValue("--background").trim(),
    foreground: rootStyle.getPropertyValue("--foreground").trim(),
    separator: rootStyle.getPropertyValue("--separator").trim(),
    separatorLight: rootStyle.getPropertyValue("--separator-light").trim(),

    primaryrgb: rootStyle.getPropertyValue("--primary-rgb").trim(),
    secondaryrgb: rootStyle.getPropertyValue("--secondary-rgb").trim(),
    tertiaryrgb: rootStyle.getPropertyValue("--tertiary-rgb").trim(),
    quaternaryrgb: rootStyle.getPropertyValue("--quaternary-rgb").trim(),

    transitionTimeShort: rootStyle
      .getPropertyValue("--transition-time-short")
      .trim()
      .replace("ms", ""),
    transitionTime: rootStyle
      .getPropertyValue("--transition-time")
      .trim()
      .replace("ms", ""),
    navSizeSlim: rootStyle.getPropertyValue("--nav-size-slim").trim(),

    borderRadiusXl: rootStyle.getPropertyValue("--border-radius-xl").trim(),
    borderRadiusLg: rootStyle.getPropertyValue("--border-radius-lg").trim(),
    borderRadiusMd: rootStyle.getPropertyValue("--border-radius-md").trim(),
    borderRadiusSm: rootStyle.getPropertyValue("--border-radius-sm").trim(),
    spacingHorizontal: rootStyle
      .getPropertyValue("--main-spacing-horizontal")
      .trim(),

    sm: rootStyle.getPropertyValue("--sm").trim(),
    md: rootStyle.getPropertyValue("--md").trim(),
    lg: rootStyle.getPropertyValue("--lg").trim(),
    xl: rootStyle.getPropertyValue("--xl").trim(),
    xxl: rootStyle.getPropertyValue("--xxl").trim(),
    direction: "ltr",
  };
};

export const GroupActionTypes = {
  ADD_GROUP_REQUEST: "@@settings/ADD_GROUP_REQUEST",
  ADD_GROUP_SUCCESS: "@@settings/ADD_GROUP_SUCCESS",
  ADD_GROUP_FAILURE: "@@settings/ADD_GROUP_FAILURE",

  CLEAR_MESSAGES: "@@settings/CLEAR_MESSAGES",

  GET_USERGROUP_SUCESS: "@@settings/GET_USERGROUP_SUCESS",
  GET_USERGROUP_REQUEST: "@@settings/GET_USERGROUP_REQUEST",
  GET_USERGROUP_FAILURE: "@@settings/GET_USERGROUP_FAILURE",

  GET_SOCIAL_SUCESS: "@@settings/GET_SOCIAL_SUCESS",
  GET_SOCIAL_REQUEST: "@@settings/GET_SOCIAL_REQUEST",
  GET_SOCIAL_FAILURE: "@@settings/GET_SOCIAL_FAILURE",

  UPDATE_USERGROUP_SUCESS: "@@settings/UPDATE_USERGROUP_SUCESS",
  UPDATE_USERGROUP_REQUEST: "@@settings/UPDATE_USERGROUP_REQUEST",
  UPDATE_USERGROUP_FAILURE: "@@settings/UPDATE_USERGROUP_FAILURE",

  GET_FB_PAGE_SUCESS: "@@settings/GET_FB_PAGE_SUCESS",
  GET_FB_PAGE_REQUEST: "@@settings/GET_FB_PAGE_REQUEST",
  GET_FB_PAGE_FAILURE: "@@settings/GET_FB_PAGE_FAILURE",

  GET_CURRENT_GRP_SUCESS: "@@settings/GET_CURRENT_GRP_SUCESS",
  GET_CURRENT_GRP_REQUEST: "@@settings/GET_CURRENT_GRP_REQUEST",
  GET_CURRENT_GRP_FAILURE: "@@settings/GET_CURRENT_GRP_FAILURE",

  AUTH_SOCIALS_SUCESS: "@@settings/AUTH_SOCIALS_SUCESS",
  AUTH_SOCIALS_REQUEST: "@@settings/AUTH_SOCIALS_REQUEST",
  AUTH_SOCIALS_FAILURE: "@@settings/AUTH_SOCIALS_FAILURE",

  SET_CRNT_GRP_SUCESS: "@@settings/SET_CRNT_GRP_SUCESS",
  SET_CRNT_GRP_REQUEST: "@@settings/SET_CRNT_GRP_REQUEST",
  SET_CRNT_GRP_FAILURE: "@@settings/SET_CRNT_GRP_FAILURE",

  GET_PROFILE_SETTING_REQUEST: "@@settings/PROFILE_SETTING_REQUEST",
  GET_PROFILE_SETTING_SUCCESS: "@@settings/PROFILE_SETTING_SUCCESS",
  GET_PROFILE_SETTING_FAILURE: "@@settings/PROFILE_SETTING_FAILURE",

  POST_PROFILE_SETTING_REQUEST: "@@settings/GET_PROFILE_SETTING_REQUEST",
  POST_PROFILE_SETTING_SUCCESS: "@@settings/GET_PROFILE_SETTING_SUCCESS",
  POST_PROFILE_SETTING_FAILURE: "@@settings/GET_PROFILE_SETTING_FAILURE",

  POST_SYNC_REQUEST: "@@settings/POST_SYNC_REQUEST",
  POST_SYNC_SUCCESS: "@@settings/POST_SYNC_SUCCESS",
  POST_SYNC_FAILURE: "@@settings/POST_SYNC_FAILURE",

  CLEAR_FB_POSTS: "@@settings/CLEAR_FB_POSTS",

  SET_COLOR: "@@settings/SET_COLOR",
  SET_THEME_VALUES: "@@settings/SET_THEME_VALUES",
  SETTINGS_CHANGE_RADIUS: "@@settings/SETTINGS_CHANGE_RADIUS",
  SETTINGS_CHANGE_NAVCOLOR: "@@settings/SETTINGS_CHANGE_NAVCOLOR",
  SETTINGS_CHANGE_LAYOUT: "@@settings/SETTINGS_CHANGE_LAYOUT",
  SETTINGS_RESET_LAYOUT: "@@settings/SETTINGS_RESET_LAYOUT",
  CHANGE_COLOR: "@@settings/CHANGE_COLOR",
};

export const groupInitialState = {
  group: [],
  groupLoading: false,
  accountSubmit: false,
  profileLoading: false,
  groupError: "",
  groupMessage: "",
  profileDetails: [],
  socialList: [],
  current_group: null,
  color: DEFAULT_SETTINGS.COLOR,
  layout: DEFAULT_SETTINGS.LAYOUT,
  radius: DEFAULT_SETTINGS.RADIUS,
  navColor: DEFAULT_SETTINGS.NAV_COLOR,
  themeValues: getComputedValues(),
  syncedData: [],
  syncLoad: false,
};
